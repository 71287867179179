import { bnplMerchants } from "@mychili/frontend-libs-api-core";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEY_ORDERS } from "entities/orders";
import { getOrders } from "shared/api";

export const useOrders = (
  options: bnplMerchants.GetApiMerchantsServiceOrdersParams = {},
) =>
  useQuery({
    queryFn: () => getOrders(options),
    queryKey: [QUERY_KEY_ORDERS, options],
  });
