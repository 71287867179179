import { bnplMerchants } from "@mychili/frontend-libs-api-core";

export type GetEmployeesNamesResponseData =
  | {
      id?: bnplMerchants.GetEmployeesNamesMany["id"];
      name: bnplMerchants.GetEmployeesNamesMany["name"];
    }[]
  | undefined;

export const getEmployeesNames =
  async (): Promise<GetEmployeesNamesResponseData> => {
    const response = await bnplMerchants.getApiMerchantsServiceEmployeesNames();

    if (!response.data.data) {
      throw new Error("Error getting employees names");
    }

    return response.data.data;
  };
