export const enum CountryCode {
  MY = "MY",
}

export const enum ProjectCode {
  AHP = "AHP",
}

export const enum ThemeKey {
  AhaPay = "AhaPay",
}
