import { bnplMerchants } from "@mychili/frontend-libs-api-core";

export type GetAgreementsResponseData = {
  id: bnplMerchants.AgreementMany["id"];
  created: bnplMerchants.AgreementMany["created"];
  archived: bnplMerchants.AgreementMany["archived"];
  updated: bnplMerchants.AgreementMany["updated"];
  merchantId: bnplMerchants.AgreementMany["merchant_id"];
  agreementNumber: bnplMerchants.AgreementMany["agreement_number"];
  settlementDelay: bnplMerchants.AgreementMany["settlement_delay"];
};

export const getAgreements = async (): Promise<GetAgreementsResponseData[]> => {
  const response = await bnplMerchants.getApiMerchantsServiceAgreements();

  return (
    response.data.data?.items?.map((i) => ({
      id: i.id,
      created: i.created,
      archived: i.archived,
      updated: i.updated,
      merchantId: i.merchant_id,
      agreementNumber: i.agreement_number,
      settlementDelay: i.settlement_delay,
    })) || []
  );
};
