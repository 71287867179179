import { bnplMerchants } from "@mychili/frontend-libs-api-core";

export type ExportSettlementParams = {
  settlementId: string;
  params: bnplMerchants.GetApiMerchantsServiceSettlementsIdExportParams;
};

export const exportSettlement = async ({
  settlementId,
  params,
}: ExportSettlementParams) => {
  const response =
    await bnplMerchants.getApiMerchantsServiceSettlementsIdExport(
      settlementId,
      params,
      {
        responseType: "blob",
      },
    );

  return response.data as unknown;
};
