import { useSearchParams } from "react-router-dom";
import { SortingOptions } from "shared/ui";

import { getSearchParamsObject, toCamelCase } from "../../";
import { SEARCH_PARAM_NAME_ORDER } from "../constants";

const getSelectedOptionData = <T>(
  order: string,
  sortingOptions: SortingOptions<T>,
) => {
  if (!order) return undefined;

  const isDesc = order.substring(0, 1) === "-";
  const field = toCamelCase(isDesc ? order.slice(1) : order) as keyof T;

  return sortingOptions[field]?.find((option) => option.id === order);
};

export const useTableSorting = <T>(sortingOptions: SortingOptions<T>) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { order } = getSearchParamsObject(searchParams);

  const handleSortingChange = (value: string | string[]) => {
    setSearchParams({
      ...getSearchParamsObject(searchParams),
      ...{ [SEARCH_PARAM_NAME_ORDER]: value },
    });
  };

  const selectedOption = getSelectedOptionData(order as string, sortingOptions);

  return {
    handleSortingChange,
    sortingState: selectedOption,
  };
};
