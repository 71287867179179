import { useBoolean } from "@mychili/ui-web";
import {
  useInvalidateMerchant,
  useMerchant,
  useUpdateMerchant,
  useUploadMerchantLogo,
} from "entities/merchant";
import {
  MerchantFormDataWithLogo,
  UpdateMerchant,
} from "features/update-merchant";
import {
  getS3ImageSrc,
  showSnackbarError,
  showSnackbarSuccess,
} from "shared/lib";
import {
  Box,
  Button,
  Card,
  InfoBlock,
  Loader,
  Logo,
  Stack,
  Typography,
} from "shared/ui";

export const MerchantInfo = () => {
  const merchant = useMerchant();

  const invalidateMerchant = useInvalidateMerchant();
  const uploadMerchantLogo = useUploadMerchantLogo();
  const updateMerchant = useUpdateMerchant();

  const editModal = useBoolean();

  const handleEditClick = () => {
    editModal.on();
  };

  const handleCopy = (title: string) => {
    showSnackbarSuccess(`${title} copied`);
  };

  const handleUpdateSubmit = (data: MerchantFormDataWithLogo) => {
    updateMerchant.mutate(
      {
        name: data.name,
        logo_url: data.logo ? undefined : null,
      },
      {
        onError: () => {
          showSnackbarError("Error updating merchant");
        },
        onSuccess: (response) => {
          if (data.logo && response.id) {
            uploadMerchantLogo.mutate(data.logo, {
              onError: () => {
                showSnackbarError("Error uploading merchant logo");
              },
              onSuccess: invalidateMerchant,
            });
          } else {
            invalidateMerchant();
          }
          showSnackbarSuccess("Changes saved");

          editModal.off();
        },
      },
    );
  };

  if (merchant.isLoading) return <Loader />;

  const logoSrc = getS3ImageSrc(merchant.data?.logoUrl);

  return (
    <>
      <Card sx={{ width: 500, marginX: "auto", p: 3 }}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Logo src={logoSrc} size="s" />
          <Typography variant="bigTextSemiBold">
            {merchant.data?.name}
          </Typography>
        </Stack>

        <Stack spacing={1} mt={2.5}>
          <InfoBlock
            title="Registered name"
            description={merchant.data?.name ?? ""}
            canCopy
            onCopy={() => handleCopy("Registered name")}
          />
          <InfoBlock
            title="Official name"
            description={merchant.data?.owner ?? ""}
            canCopy
            onCopy={() => handleCopy("Official name")}
          />
          <InfoBlock
            title="Merchant ID"
            description={merchant.data?.id ?? ""}
            canCopy
            onCopy={() => handleCopy("Merchant ID")}
          />
          <InfoBlock
            title="Address"
            description={merchant.data?.address ?? ""}
            canCopy
            onCopy={() => handleCopy("Address")}
          />
          <InfoBlock
            title="Registration No."
            description={merchant.data?.registrationNumber ?? ""}
            canCopy
            onCopy={() => handleCopy("Registeration number")}
          />
        </Stack>

        <Box>
          <Button
            onClick={handleEditClick}
            variant="link"
            sx={{
              "&.MuiButtonBase-root": {
                paddingX: 0,
              },
            }}
          >
            Change information
          </Button>
        </Box>
      </Card>

      <UpdateMerchant
        initialData={{
          name: merchant.data?.name ?? "",
          logoUrl: merchant.data?.logoUrl,
        }}
        isOpen={editModal.value}
        onClose={editModal.off}
        onSubmit={handleUpdateSubmit}
      />
    </>
  );
};
