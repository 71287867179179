import { bnplMerchants } from "@mychili/frontend-libs-api-core";
import { LabelProps } from "shared/ui";

export const getStatusMappingData = (
  status: bnplMerchants.GetApiMerchantsServiceSettlementsStatus,
): { title: string; color: LabelProps["color"] } => {
  switch (status) {
    case "DRAFT":
      return {
        title: "Draft",
        color: "neutral",
      };
    case "DONE":
      return {
        title: "Done",
        color: "success",
      };
    case "SENT":
    case "ACCEPTED":
      return {
        title: "Sent",
        color: "info",
      };
    case "CANCELLED":
      return {
        title: "Canceled",
        color: "error",
      };
    default:
      return {
        title: "",
        color: "neutral",
      };
  }
};
