import { ColumnDef } from "@tanstack/react-table";
import { formatSettlementNumber } from "entities/settlements";
import { GetSettlementsItem } from "shared/api";
import { format, formatDate } from "shared/lib";
import { Label, SortingOptions } from "shared/ui";

import { getStatusMappingData } from "./get-status-mapping-data";


export const COLUMNS: ColumnDef<GetSettlementsItem, any>[] = [
  {
    accessorKey: "created",
    header: "Date",
    cell: (cell) => {
      const value = cell.getValue();
      return value instanceof Date ? formatDate(value, "DD/MM/YYYY") : "";
    },
  },
  {
    accessorKey: "settlementNumber",
    header: "Payout ID",
    cell: (cell) => {
      const value = cell.getValue();
      return formatSettlementNumber(value);
    },
  },
  {
    accessorKey: "status",
    header: "State",
    cell: (cell) => {
      const { title, color } = getStatusMappingData(cell.getValue());
      return (
        <Label color={color} showIndicator={true}>
          {title}
        </Label>
      );
    },
  },
  {
    accessorKey: "salesAmount",
    header: "Amount",
    cell: (cell) => {
      const value = cell.getValue();
      return value ? format(value) : undefined;
    },
  },
  {
    accessorKey: "ordersCount",
    header: "Number of Orders",
  },
];

export const SORTING_OPTIONS: SortingOptions<GetSettlementsItem> = {
  created: [
    {
      name: "Newest first",
      id: "-created",
    },
    {
      name: "Oldest first",
      id: "created",
    },
  ],
  status: [
    {
      name: "A-Z",
      id: "-status",
    },
    {
      name: "Z-A",
      id: "status",
    },
  ],
  salesAmount: [
    {
      name: "From higher to lower",
      id: "-sales_amount",
    },
    {
      name: "From lower to higher",
      id: "sales_amount",
    },
  ],
};
