import { useNavigate } from "react-router-dom";

import { Link } from "../link";

export type TableCellLinkButtonProps = {
  text: string;
  path: string;
};

export const TableCellLinkButton = ({
  text,
  path,
}: TableCellLinkButtonProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(path);
  };

  return (
    <Link
      onClick={handleClick}
      sx={{
        fontSize: "12px",
        lineHeight: "18px",
        cursor: "pointer",
        textDecoration: "none",
        color: "info.dark",
        "&:hover": {
          textDecoration: "underline",
        },
        "&:active": {
          color: "info.20",
        },
      }}
    >
      {text}
    </Link>
  );
};
