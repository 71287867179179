import { bnplMerchants } from "@mychili/frontend-libs-api-core";

export type GetSettlementOrdersItem = {
  id: bnplMerchants.GetSettlementOrderMany["id"];
  created: bnplMerchants.GetSettlementOrderMany["created"];
  updated: bnplMerchants.GetSettlementOrderMany["updated"];
  archived: bnplMerchants.GetSettlementOrderMany["archived"];
  settlementId: bnplMerchants.GetSettlementOrderMany["settlement_id"];
  orderId: bnplMerchants.GetSettlementOrderMany["order_id"];
  orderAmount: bnplMerchants.GetSettlementOrderMany["order_amount"];
  settlementAmount: bnplMerchants.GetSettlementOrderMany["settlement_amount"];
  mdr: bnplMerchants.GetSettlementOrderMany["mdr"];
  commissionAmount: bnplMerchants.GetSettlementOrderMany["commission_amount"];
  orderNumber: bnplMerchants.GetSettlementOrderMany["order_number"];
};

export type GetSettlementOrdersResponseData = {
  items: GetSettlementOrdersItem[] | undefined;
  pagination: bnplMerchants.Pagination | undefined;
};

export type GetSettlementOrdersParams = {
  settlementId?: string;
  params: bnplMerchants.GetApiMerchantsServiceSettlementsIdOrdersParams;
};

export const getSettlemenOrders = async ({
  settlementId,
  params,
}: GetSettlementOrdersParams): Promise<GetSettlementOrdersResponseData> => {
  const response =
    await bnplMerchants.getApiMerchantsServiceSettlementsIdOrders(
      settlementId!,
      params,
    );

  if (!response.data.data) {
    throw new Error("Error getting settlement orders");
  }

  return {
    items:
      response.data.data.items?.map((order) => ({
        id: order.id,
        created: order.created,
        updated: order.updated,
        archived: order.archived,
        settlementId: order.settlement_id,
        orderId: order.order_id,
        orderAmount: order.order_amount,
        settlementAmount: order.settlement_amount,
        mdr: order.mdr,
        commissionAmount: order.commission_amount,
        orderNumber: order.order_number,
      })) || [],
    pagination: response.data.data.pagination,
  };
};
