import { getCountdown, useTimer } from "shared/lib";
import { Button, Typography } from "shared/ui";

export const ResendCodeButton = ({
  countdown = 0,
  onResend,
}: {
  countdown?: number;
  onResend: () => void;
}) => {
  const timer = useTimer(countdown);

  if (timer.state === "started") {
    return (
      <Typography variant="regularTextRegular">
        You can resend the code after {getCountdown(timer.seconds)}
      </Typography>
    );
  }

  return (
    <Button onClick={onResend} size="medium" variant="text">
      Resend code
    </Button>
  );
};
