import { ROUTES } from "shared/routing";

type Params = {
  tradeOutletIdIn?: string;
  employeeIdIn?: string;
  search?: string;
};

export const getOrdersPath = (params: Params): string =>
  Object.entries(params).reduce(
    (res, [key, value]) => `${res}?${key}=${value}`,
    String(ROUTES.orders),
  );
