import { bnplMerchants } from "@mychili/frontend-libs-api-core";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEY_SETTLEMENTS } from "entities/settlements";
import { getSettlements } from "shared/api";

export const useSettlements = (
  options: bnplMerchants.GetApiMerchantsServiceCrmSettlementsParams = {},
) =>
  useQuery({
    queryFn: () => getSettlements(options),
    queryKey: [QUERY_KEY_SETTLEMENTS, options],
  });
