import { useMutation } from "@tanstack/react-query";
import { queryClient } from "app/providers";
import { QUERY_KEY_EMPLOYEES } from "entities/employees";
import { updateEmployee } from "shared/api";

export const useUpdateEmployee = () =>
  useMutation({
    mutationFn: updateEmployee,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_EMPLOYEES],
      });
    },
  });
