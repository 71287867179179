import { bnplMerchants } from "@mychili/frontend-libs-api-core";
import { useBoolean } from "@mychili/ui-web";
import { Row } from "@tanstack/react-table";
import { useSettlements } from "entities/settlements";
import { SettlementDetails } from "features/settlement-details";
import { UnpaidOrders } from "features/unpaid-orders";
import { useEffect, useMemo, useState } from "react";
import { GetSettlementsItem } from "shared/api";
import { format, useTablePagination, useTableSorting } from "shared/lib";
import {
  BaseTable,
  BaseTablePagination,
  BaseTableSummary,
  BaseTableWrapper,
  Card,
  LoadingOverlay,
  Stack,
  Typography,
} from "shared/ui";

import { COLUMNS, SORTING_OPTIONS } from "../lib";

export const SettlementList = () => {
  const pagination = useTablePagination();

  const showUnpaidOrders = useBoolean();

  const { handleSortingChange, sortingState } =
    useTableSorting<GetSettlementsItem>(SORTING_OPTIONS);

  const settlements = useSettlements({
    limit: pagination.currentLimit,
    offset: pagination.offset,
    order_by:
      sortingState?.id as bnplMerchants.GetApiMerchantsServiceSettlementsOrderBy,
  });

  const [selectedRowId, setSelectedRowId] = useState<string>();

  const summaryItems = [
    {
      key: "unpaid_orders",
      title: settlements.data?.summary?.unsettledOrdersCount || "",
      description: "Unpaid orders",
      onClick: () => {
        showUnpaidOrders.on();
        setSelectedRowId(undefined);
      },
    },
    {
      key: "bonuses",
      title: format(0),
      description: "Bonuses",
    },
    {
      key: "debit",
      title: format(0),
      description: "Debit",
    },
  ];

  const handleRowSelect = (row: Row<GetSettlementsItem>) => {
    showUnpaidOrders.off();
    setSelectedRowId(row.id);
  };

  useEffect(() => {
    if (
      selectedRowId === undefined &&
      settlements.data?.items?.length &&
      settlements.data?.items[0] &&
      !showUnpaidOrders.value
    ) {
      setSelectedRowId(settlements.data?.items[0].id);
    }
  }, [selectedRowId, settlements.data?.items, showUnpaidOrders.value]);

  const selectedSettlement = useMemo(() => {
    return settlements.data?.items?.find((item) => item.id === selectedRowId);
  }, [selectedRowId, settlements.data?.items]);

  const getRowId = (row: GetSettlementsItem) => row.id!;

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2.5}
      >
        <Typography variant="headlineH1">Payout</Typography>
      </Stack>

      <Stack direction="row" spacing={2}>
        <BaseTableWrapper sx={{ height: 627, width: 744 }}>
          <BaseTableSummary items={summaryItems} />
          <BaseTable
            columns={COLUMNS}
            data={settlements.data?.items || []}
            sortingState={sortingState}
            onSortingChange={handleSortingChange}
            sortingOptions={SORTING_OPTIONS}
            isDataLoading={settlements.isLoading}
            selectedRowId={selectedRowId}
            onRowSelect={handleRowSelect}
            getRowId={getRowId}
          />
          <BaseTablePagination
            state={{
              ...pagination,
              totalCount: settlements.data?.pagination?.total,
            }}
          />
        </BaseTableWrapper>

        <Card sx={{ height: 627, flex: 1 }}>
          {showUnpaidOrders.value ? (
            <UnpaidOrders />
          ) : (
            <SettlementDetails settlement={selectedSettlement} />
          )}
        </Card>
      </Stack>

      <LoadingOverlay open={settlements.isLoading} />
    </>
  );
};
