import { Login } from "features/login";
import { Stack } from "shared/ui";

export const PageLogin = () => {
  return (
    <Stack
      maxWidth="md"
      mx="auto"
      justifyContent="center"
      sx={{
        height: "100%",
      }}
    >
      <Login />
    </Stack>
  );
};
