import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { getCode } from "shared/api";

export const useGetCode = () => {
  return useMutation({
    mutationFn: getCode,
  });
};

export const getCodeErrorMessage = (error: unknown) => {
  if (!(error instanceof AxiosError)) {
    return;
  }

  const errorCode = error.response?.data?.error?.code;

  if (errorCode === "countdown_error") {
    return "Too many requests";
  }

  return "Wrong phone number";
};
