import { IconClose } from "@mychili/ui-web";

import { IconButton, IconButtonProps } from "../icon-button";

export type CloseButtonProps = IconButtonProps;

export const CloseButton = (props: CloseButtonProps) => (
  <IconButton {...props}>
    <IconClose />
  </IconButton>
);
