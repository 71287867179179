import { bnplMerchants } from "@mychili/frontend-libs-api-core";

import { GetMerchantResponseData } from "./get-merchant";

export const updateMerchant = async (
  options: bnplMerchants.UpdateMerchantSchema,
): Promise<GetMerchantResponseData> => {
  const response =
    await bnplMerchants.patchApiMerchantsServiceMerchant(options);

  if (!response.data.data) {
    throw new Error("Error updating merchant");
  }

  const {
    address,
    archived,
    created,
    id,
    logo_url,
    name,
    owner,
    registration_number,
    updated,
    website,
  } = response.data.data;

  return {
    address,
    archived,
    created: created ? new Date(created) : undefined,
    id,
    logoUrl: logo_url,
    name,
    owner,
    registrationNumber: registration_number,
    updated: updated ? new Date(updated) : undefined,
    website,
  };
};
