import { bnplMerchants } from "@mychili/frontend-libs-api-core";

export type GetTradeOutletNamesItem = {
  id?: bnplMerchants.GetTradeOutletsNamesMany["id"];
  name: bnplMerchants.GetTradeOutletsNamesMany["name"];
  location?: bnplMerchants.GetTradeOutletsNamesMany["location"];
};

export type GetTradeOutletsNamesResponseData =
  | GetTradeOutletNamesItem[]
  | undefined;

export const getTradeOutletsNames =
  async (): Promise<GetTradeOutletsNamesResponseData> => {
    const response =
      await bnplMerchants.getApiMerchantsServiceTradeOutletsNames();

    if (!response.data.data) {
      throw new Error("Error getting trade outlets names names");
    }

    return response.data.data;
  };
