import { GetOrdersItem } from "shared/api";
import { LabelProps } from "shared/ui";

export const getStatusMappingData = (
  status: GetOrdersItem["status"],
): { title: string; color: LabelProps["color"] } => {
  switch (status) {
    case "PENDING":
    case "PROCESSING":
    case "CONFIRMED":
      return {
        title: "Pending",
        color: "info",
      };
    case "ACTIVE":
    case "REPAID":
      return {
        title: "Active",
        color: "success",
      };
    case "REFUNDED":
      return {
        title: "Refunded",
        color: "warning",
      };
    case "EXPIRED":
    case "REJECTED":
    case "CANCELLED":
      return {
        title: "Canceled",
        color: "error",
      };
    default:
      return {
        title: "",
        color: "neutral",
      };
  }
};
