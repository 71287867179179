import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  Row,
  TableOptions,
  useReactTable,
} from "@tanstack/react-table";
import { useState } from "react";
import {
  Box,
  Button,
  IconClose,
  IconFilterList,
  Popover,
  Show,
  Stack,
  Typography,
} from "shared/ui";

import {
  FilterItem,
  FilterType,
  SortingOption,
  SortingOptions,
} from "../types";
import { Filter } from "./filter";
import { NoResults } from "./no-results";
import { OptionsDropdown, OptionsDropdownProps } from "./options-dropdown";

type BaseTableProps<T> = {
  columns: ColumnDef<T, any>[];
  data: T[];
  getRowId: TableOptions<T>["getRowId"];
  onRowSelect?: (row: Row<T>) => void;
  selectedRowId?: string | null;
  onSortingChange?: (value: string | string[]) => void;
  sortingOptions?: SortingOptions<T>;
  sortingState?: SortingOption | undefined;
  rowSelection?: boolean;
  filters?: FilterItem[];
  filterState?: any;
  onFiltersReset?: () => void;
  onFilterChange?: (
    field: string,
    values: string[] | Date[] | undefined,
    type: FilterType,
  ) => void;
  isDataLoading?: boolean;
  deletedColumnId?: string;
};

export const BaseTable = <T,>({
  data,
  columns,
  sortingState,
  onSortingChange,
  sortingOptions,
  filters,
  onFilterChange,
  filterState,
  onFiltersReset,
  selectedRowId,
  onRowSelect,
  isDataLoading,
  getRowId,
  deletedColumnId,
}: BaseTableProps<T>) => {
  const columnVisibility = deletedColumnId
    ? {
        [deletedColumnId]: false,
      }
    : undefined;

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    getRowId,
    defaultColumn: {
      size: 200,
      maxSize: 200,
    },
    state: {
      columnVisibility,
    },
  });

  const [activeSortingColumnKey, setActiveSortingColumnKey] =
    useState<keyof T>();

  const handleRowSelect = (row: Row<T>) => {
    if (onRowSelect) {
      onRowSelect(row);
    }
  };

  const [sortingAnchorEl, setSortingAnchorEl] =
    useState<HTMLTableCellElement | null>(null);

  const handleSortingClick = (
    columnKey: keyof T,
    e: React.MouseEvent<HTMLTableCellElement>,
  ) => {
    setActiveSortingColumnKey(columnKey);
    setSortingAnchorEl(e.currentTarget);
  };

  const isTableEmpty = !isDataLoading && data.length === 0;

  return (
    <>
      {/* Filters */}
      {filters && onFilterChange && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          p={2}
        >
          <Stack direction="row" gap={1}>
            {filters.map((filterProps) => (
              <Filter
                {...filterProps}
                key={filterProps.field}
                onChange={onFilterChange}
                value={filterState[filterProps.field]}
              />
            ))}
          </Stack>

          <Show when={isTableEmpty}>
            <Button
              onClick={onFiltersReset}
              variant="link"
              endIcon={<IconClose />}
            >
              Clear filters
            </Button>
          </Show>
        </Stack>
      )}

      {isTableEmpty ? (
        <NoResults onFiltersReset={onFiltersReset} />
      ) : (
        <>
          <Box sx={{ overflowY: "auto" }}>
            <table
              cellSpacing={0}
              cellPadding={0}
              style={{
                width: "100%",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              {/* Header */}
              <thead style={{ position: "sticky", top: 0 }}>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header, index, arr) => {
                      const isSortable =
                        sortingOptions?.[header.column.id as keyof T];

                      return (
                        <th
                          key={header.id}
                          style={{ width: header.getSize() }}
                          onClick={
                            isSortable
                              ? (e) =>
                                  handleSortingClick(
                                    header.column.id as keyof T,
                                    e,
                                  )
                              : undefined
                          }
                        >
                          <Box
                            padding={1}
                            pr={0}
                            sx={{
                              backgroundColor: "neutral.96",
                              borderBottom: "1px solid",
                              borderColor: "neutral.90",
                              borderTopLeftRadius: index === 0 ? 8 : 0,
                              borderTopRightRadius:
                                index === arr.length - 1 ? 8 : 0,
                              cursor: isSortable ? "pointer" : "auto",
                              "&:hover": {
                                backgroundColor: "neutral.95",
                              },
                              "&:active": {
                                backgroundColor: "neutral.90",
                                borderColor: "neutral.80",
                              },
                              height: 41,
                              alignContent: "center",
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              justifyContent="space-between"
                              pr={1}
                              sx={{
                                borderRight:
                                  index === arr.length - 1
                                    ? "none"
                                    : "1px solid",
                                borderColor: "neutral.90",
                              }}
                            >
                              <Typography
                                variant="smallTextSemiBold"
                                whiteSpace="nowrap"
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext(),
                                )}
                              </Typography>
                              {isSortable && <IconFilterList />}
                            </Stack>
                          </Box>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>

              {/* Body */}
              <tbody>
                {table.getRowModel().rows.map((row, index, arr) => {
                  const isRowSelected = onRowSelect
                    ? row.id === selectedRowId
                    : false;
                  const isLastRow = index === arr.length - 1;

                  return (
                    <tr key={row.id} onClick={() => handleRowSelect(row)}>
                      {row.getVisibleCells().map((cell) => {
                        const isDeleted = deletedColumnId
                          ? row.getValue(deletedColumnId)
                          : false;

                        return (
                          <td key={cell.id}>
                            <Box
                              sx={{
                                cursor: onRowSelect ? "pointer" : "auto",
                                padding: 1,
                                verticalAlign: "top",
                                borderBottom: isLastRow
                                  ? undefined
                                  : "1px solid",
                                backgroundColor: isRowSelected
                                  ? "info.95"
                                  : "common.white",
                                borderBottomColor: isRowSelected
                                  ? "info.90"
                                  : "neutral.95",
                                height: "64px",
                              }}
                            >
                              <Typography
                                variant="smallTextRegular"
                                sx={{
                                  textDecoration: isDeleted
                                    ? "line-through"
                                    : undefined,
                                }}
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext(),
                                )}
                              </Typography>
                            </Box>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Box>
        </>
      )}

      {/* Sorting */}
      {sortingOptions && onSortingChange && (
        <Popover
          anchorEl={sortingAnchorEl}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          open={Boolean(sortingAnchorEl)}
          onClose={() => setSortingAnchorEl(null)}
        >
          <OptionsDropdown
            options={
              (sortingOptions[
                activeSortingColumnKey
              ] as OptionsDropdownProps["options"]) || []
            }
            value={sortingState?.id as string}
            onChange={onSortingChange}
            title="Sorting"
            type="radio"
          />
        </Popover>
      )}
    </>
  );
};
