// TODO: create config
const defaultLocale = "en";
const currency = "MYR";
const currencyMaxFractionDigits = 1;

export const format = (value: number): string => {
  const formatter = new Intl.NumberFormat(defaultLocale, {
    currency,
    currencyDisplay: "narrowSymbol",
    maximumFractionDigits: currencyMaxFractionDigits,
    minimumFractionDigits: 0,
    style: "currency",
  });

  return formatter.format(value);
};
