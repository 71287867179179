import { bnplMerchants } from "@mychili/frontend-libs-api-core";

export const uploadMerchantLogo = async (
  logo: bnplMerchants.PostApiMerchantsServiceCrmMerchantsIdUploadLogoBody["logo"],
) => {
  const response =
    await bnplMerchants.postApiMerchantsServiceMerchantUploadLogo({ logo });

  if (!response.data.data) {
    const errorMessage = "Error uploading merchant logo";
    throw new Error(errorMessage);
  }

  return response.data.data;
};
