import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import { SEARCH_PARAM_NAME_LIMIT, SEARCH_PARAM_NAME_PAGE } from "../constants";

const DEFAULT_LIMIT = 50;

export const useTablePagination = (limit = DEFAULT_LIMIT) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const page = Number(searchParams.get(SEARCH_PARAM_NAME_PAGE));

  const currentPage = page > 0 ? page - 1 : 0;
  const currentLimit =
    Number(searchParams.get(SEARCH_PARAM_NAME_LIMIT)) || limit;

  // Запоминаем общее количество, чтобы при переходе по страницам счетчик не сбрасывался на 0 of 0
  const [totalCount, setTotalCount] = useState(0);

  const updateSearchLocation = (params: {}) => {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      ...params,
    });
  };

  // Pagination handlers
  const handleCurrentPageChange = (value: number) => {
    updateSearchLocation({ [SEARCH_PARAM_NAME_PAGE]: value + 1 });
  };

  const handlePageSizeUpdate = (value: number) => {
    updateSearchLocation({
      [SEARCH_PARAM_NAME_LIMIT]: value,
      ...(value > currentLimit && { [SEARCH_PARAM_NAME_PAGE]: 1 }),
    });
  };

  const handlePaginationReset = () => {
    updateSearchLocation({ [SEARCH_PARAM_NAME_PAGE]: 0 });
  };

  return {
    offset: currentPage * currentLimit,
    currentLimit,
    currentPage,
    handleCurrentPageChange,
    handlePageSizeUpdate,
    handlePaginationReset,
    totalCount,
    setTotalCount,
  };
};
