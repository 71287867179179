import React from "react";

import { Session } from "./set-session";

export const SessionContext = React.createContext<Session | undefined>(
  undefined,
);

export const useSession = () => {
  return React.useContext(SessionContext);
};
