import { queryClient } from "app/providers";

import {
  ACCESS_TOKEN_STORAGE_KEY,
  queryKey,
  REFRESH_TOKEN_STORAGE_KEY,
} from "../../lib";

export type Session = {
  accessToken: string | null;
  refreshToken: string | null;
};

export const setSession = (
  tokens: Pick<Session, "accessToken" | "refreshToken">,
) => {
  const { accessToken, refreshToken } = tokens;

  if (accessToken) {
    localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, accessToken);
  }

  if (refreshToken) {
    localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, refreshToken);
  }

  try {
    // const token = decode(accessToken);
    // https://tkdodo.eu/blog/practical-react-query#dont-use-the-querycache-as-a-local-state-manager
    return queryClient.setQueryData(queryKey, { accessToken, refreshToken });
  } catch {
    // TODO: SENTRY LOG
  }
};
