import { LayoutMain } from "widgets/layout-main";
import { SettlementList } from "widgets/settlement-list";

export const PageSettlements = () => {
  return (
    <LayoutMain>
      <SettlementList />
    </LayoutMain>
  );
};
