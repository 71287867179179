import { yupResolver } from "@hookform/resolvers/yup";
import { useBoolean } from "@mychili/ui-web";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { getS3ImageSrc } from "shared/lib";
import {
  BaseDialog,
  Box,
  Button,
  IconStore,
  InfoCard,
  Input,
  LogoUpload,
  Stack,
  Typography,
} from "shared/ui";

import { merchantSchema } from "../lib";

type MerchantFormData = {
  name: string;
  logoUrl?: string | null;
};

export type MerchantFormDataWithLogo = MerchantFormData & {
  logo: Blob | undefined;
};

export type UpdateMechantProps = {
  initialData: MerchantFormData;
  onSubmit: (data: MerchantFormDataWithLogo) => void;
  onClose: () => void;
  isOpen: boolean;
};

export const UpdateMerchant = ({
  initialData,
  onSubmit,
  isOpen,
  onClose,
}: UpdateMechantProps) => {
  const [logoBlob, setLogoBlob] = useState<Blob>();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<MerchantFormData>({
    resolver: yupResolver(merchantSchema),
    reValidateMode: "onSubmit",
    defaultValues: initialData,
  });

  const handleFormSubmit = (data: MerchantFormData) => {
    onSubmit({
      ...data,
      logo: logoBlob,
    });
  };

  const handleLogoUploadComplete = (blob: Blob | undefined) => {
    setLogoBlob(blob);
  };

  const editLimitationWarning = useBoolean(true);

  return (
    <BaseDialog isOpen={isOpen} onClose={onClose} title="Edit merchant info">
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Stack
          spacing={2.5}
          sx={{
            p: 2.5,
            mt: 2.5,
            borderTop: "1px solid",
            borderColor: "neutral.95",
          }}
        >
          {editLimitationWarning.value && (
            <Box
              sx={{
                // @TODO Remove after adding InfoCard s size to DS
                ".MuiTypography-mediumTextMedium": {
                  fontSize: "16px",
                  lineHeight: "24px",
                },
                ".MuiTypography-regularTextRegular": {
                  fontSize: "14px",
                  lineHeight: "21px",
                },
              }}
            >
              <InfoCard
                title="Not all information can be edited"
                description="You can only edit the Logo and Registered Name"
                iconVariant="warning"
                variant="gray"
              />
            </Box>
          )}

          <Typography mb={2} variant="smallTextMedium">
            Logo
          </Typography>
          <LogoUpload
            onComplete={handleLogoUploadComplete}
            initialLogoSrc={getS3ImageSrc(initialData.logoUrl)}
          />

          <Typography mb={2} variant="smallTextMedium">
            Registered name
          </Typography>
          <Input
            {...register("name")}
            error={Boolean(errors.name?.message)}
            helperText={errors.name?.message}
            color="gray"
            InputProps={{
              startAdornment: <IconStore />,
              sx: {
                // @TODO Remove after adding small inputs to DS
                ".MuiInputBase-input": {
                  cursor: "pointer",
                  py: 1.5,
                  fontSize: 14,
                  lineHeight: 21,
                },
              },
            }}
          />
        </Stack>

        <Stack
          direction="row"
          spacing={1}
          p={2.5}
          // @TODO Add to ui-web after appearing in DS
          boxShadow="-1px 0px 8px 0px rgba(65, 69, 77, 0.02), 4px 0px 8px 0px rgba(65, 69, 77, 0.04);"
          sx={{
            borderTop: "1px solid",
            borderColor: "neutral.95",
          }}
        >
          <Button onClick={onClose} fullWidth variant="secondary">
            Back
          </Button>
          <Button type="submit" fullWidth>
            Save changes
          </Button>
        </Stack>
      </form>
    </BaseDialog>
  );
};
