import {
  Box,
  CardActionArea,
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  Show,
  Stack,
  Typography,
} from "@mychili/ui-web";

import { Card } from "../card";

// @TODO Move to ui-web after appearing in DS
export type CheckboxButtonProps = {
  checked?: CheckboxProps["checked"];
  onChange?: CheckboxProps["onChange"];
  color?: "gray" | "white";
  description?: string | number;
  disabled?: boolean;
  endIcon?: React.ReactNode;
  image?: string;
  text?: string | number;
};

export const CheckboxButton = ({
  color = "white",
  description,
  disabled = false,
  endIcon,
  image,
  checked,
  onChange,
  text,
}: CheckboxButtonProps) => {
  return (
    <Card
      sx={(theme) => ({
        // TODO: from theme
        color: "#aaadb3",

        "&.MuiPaper-root": {
          background:
            color === "white" ? theme.palette.common.white : "#f7f7f8",
        },
      })}
    >
      <CardActionArea>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={onChange}
              disabled={disabled}
            />
          }
          slotProps={{ typography: { flex: 1 } }}
          sx={{ m: 0, py: 1, pl: 1, pr: 2, width: "100%" }}
          label={
            <Stack alignItems="center" direction="row" spacing={2}>
              <Show when={image}>
                {(value) => (
                  <Box borderRadius={9999} component="img" src={value} />
                )}
              </Show>
              <Box flex={1}>
                <Typography
                  color={disabled ? "neutral.90" : "neutral.20"}
                  variant="regularTextRegular"
                >
                  {text}
                </Typography>
                <Show when={description}>
                  {(value) => (
                    <Typography
                      color={disabled ? "neutral.90" : "neutral.50"}
                      variant="smallTextRegular"
                    >
                      {value}
                    </Typography>
                  )}
                </Show>
              </Box>
              {endIcon}
            </Stack>
          }
        />
      </CardActionArea>
    </Card>
  );
};
