import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEY_TRADE_OUTLETS } from "entities/trade-outlets";
import { useCallback } from "react";

export const useInvalidateTradeOutlets = () => {
  const queryClient = useQueryClient();

  const invalidateTradeOutlets = useCallback(
    () =>
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_TRADE_OUTLETS] }),
    [queryClient],
  );

  return invalidateTradeOutlets;
};
