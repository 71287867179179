import { bnplMerchants } from "@mychili/frontend-libs-api-core";
import { useEmployeesOptionsV2 } from "entities/employees";
import { useOrders, useOrdersStatuses } from "entities/orders";
import { useTradeOutletsOptionsV2 } from "entities/trade-outlets";
import { GetOrdersItem } from "shared/api";
import {
  format as formatCurrency,
  useTablePagination,
  useTableSearch,
  useTableSorting,
} from "shared/lib";
import { useTableFilters } from "shared/lib";
import {
  BaseTable,
  BaseTablePagination,
  BaseTableWrapper,
  FilterType,
  LoadingOverlay,
  SearchInput,
  Stack,
  Typography,
} from "shared/ui";

import { COLUMNS, SORTING_OPTIONS } from "../lib";

export const OrderList = () => {
  const pagination = useTablePagination();

  const { searchValue, handleSearchClear, handleSearchSubmit } =
    useTableSearch();

  const { handleSortingChange, sortingState } =
    useTableSorting<GetOrdersItem>(SORTING_OPTIONS);

  const employeesOptions = useEmployeesOptionsV2();
  const tradeOutletsOptions = useTradeOutletsOptionsV2();
  const ordersStatuses = useOrdersStatuses();

  const filtersConfig = [
    {
      field: "created",
      type: FilterType.Range,
      entityLabel: "Dates",
    },
    {
      field: "status",
      type: FilterType.Multiple,
      entityLabel: "States",
      options: ordersStatuses.data?.map((status) => ({
        id: status,
        name: status,
      })),
    },
    {
      field: "tradeOutletId",
      type: FilterType.Multiple,
      entityLabel: "Stores",
      options: tradeOutletsOptions.data || [],
    },
    {
      field: "employeeId",
      type: FilterType.Multiple,
      entityLabel: "Employees",
      options: employeesOptions.data || [],
    },
  ];

  const { filterState, handleFilterChange, handleFiltersReset } =
    useTableFilters<GetOrdersItem>(filtersConfig);

  const orders = useOrders({
    limit: pagination.currentLimit,
    offset: pagination.offset,
    trade_outlet_id_in: filterState.tradeOutletId,
    employee_id_in: filterState.employeeId,
    status_in: filterState.status,
    created_ge:
      filterState.created?.length === 2
        ? filterState.created[0].toISOString()
        : undefined,
    created_le:
      filterState.created?.length === 2
        ? filterState.created[1].toISOString()
        : undefined,
    order_by:
      sortingState?.id as bnplMerchants.GetApiMerchantsServiceOrdersOrderBy,
    search_text: searchValue,
  });

  const totalAmount = Number(orders.data?.summary?.totalAmount);

  const getRowId = (row: GetOrdersItem) => row.id!;

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2.5}
      >
        <Typography variant="headlineH1">Orders</Typography>

        <SearchInput
          onClear={handleSearchClear}
          onSubmit={handleSearchSubmit}
          defaultValue={searchValue}
        />
      </Stack>

      <BaseTableWrapper sx={{ height: 560 }}>
        <BaseTable
          columns={COLUMNS}
          data={orders.data?.items || []}
          sortingState={sortingState}
          onSortingChange={handleSortingChange}
          sortingOptions={SORTING_OPTIONS}
          filters={filtersConfig}
          filterState={filterState}
          onFilterChange={handleFilterChange}
          onFiltersReset={handleFiltersReset}
          isDataLoading={orders.isLoading}
          getRowId={getRowId}
        />
        <BaseTablePagination
          bottomLabel={`Total Amount: ${formatCurrency(totalAmount)}`}
          state={{
            ...pagination,
            totalCount: orders.data?.pagination?.total,
          }}
        />
      </BaseTableWrapper>

      <LoadingOverlay open={orders.isLoading} />
    </>
  );
};
