import { bnplMerchants } from "@mychili/frontend-libs-api-core";
import { useQuery } from "@tanstack/react-query";
import { getEmployeeOutlets } from "shared/api";

import { QUERY_KEY_EMPLOYEE_OUTLETS } from "../../lib";

type UseEmployeeOutletsParams = {
  variables: bnplMerchants.GetApiMerchantsServiceEmployeeOutletsParams;
  enabled?: boolean;
};

export const useEmployeeOutlets = ({
  variables,
  enabled = false,
}: UseEmployeeOutletsParams) =>
  useQuery({
    queryFn: () => getEmployeeOutlets(variables),
    queryKey: [QUERY_KEY_EMPLOYEE_OUTLETS, variables],
    enabled,
  });
