import { Card, CardProps, Stack } from "shared/ui";

type BaseTableWrapperProps = CardProps;

export const BaseTableWrapper = ({
  children,
  ...cardProps
}: BaseTableWrapperProps) => (
  <Card {...cardProps}>
    <Stack
      borderRadius={1}
      height="100%"
      sx={{ backgroundColor: "common.white" }}
    >
      {children}
    </Stack>
  </Card>
);
