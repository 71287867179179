import { bnplMerchants } from "@mychili/frontend-libs-api-core";
import { useBoolean } from "@mychili/ui-web";
import { Row } from "@tanstack/react-table";
import { useEmployees } from "entities/employees";
import { useEmployeesOptionsV2 } from "entities/employees";
import { useTradeOutletsOptionsV2 } from "entities/trade-outlets";
import { useCreateEmployee } from "features/create-employee";
import { useDeleteEmployee } from "features/delete-employee";
import { EmployeeDetails } from "features/employee-details";
import { EmployeeForm } from "features/employee-form";
import { EmployeeFormDataWithTradeOutlets } from "features/employee-form";
import { useUpdateEmployee } from "features/update-employee";
import { useUpdateEmployeeOutlets } from "features/update-employee-outlets";
import { useEffect, useMemo, useState } from "react";
import { GetEmployeesItem } from "shared/api";
import {
  parseLocalPhoneNumber,
  showSnackbarSuccess,
  useTableFilters,
  useTablePagination,
  useTableSearch,
  useTableSorting,
} from "shared/lib";
import {
  BaseDialog,
  BaseTable,
  BaseTablePagination,
  BaseTableWrapper,
  Box,
  Button,
  Card,
  FilterType,
  IconAdd,
  LoadingOverlay,
  SearchInput,
  Stack,
  Typography,
} from "shared/ui";

import {
  COLUMNS,
  getEmployeeOutletsPayload,
  handleEmployeeMutationError,
  SORTING_OPTIONS,
} from "../lib";

export const EmployeeList = () => {
  const addEmployeeDialog = useBoolean();
  const editEmployeeDialog = useBoolean();
  const deleteEmployeeDialog = useBoolean();

  const createEmployee = useCreateEmployee();
  const updateEmployee = useUpdateEmployee();
  const deleteEmployee = useDeleteEmployee();
  const updateEmployeeOutlets = useUpdateEmployeeOutlets();

  const [selectedRowId, setSelectedRowId] = useState<string>();

  const pagination = useTablePagination();

  const { searchValue, handleSearchClear, handleSearchSubmit } =
    useTableSearch();

  const { handleSortingChange, sortingState } =
    useTableSorting<GetEmployeesItem>(SORTING_OPTIONS);

  const employeesOptions = useEmployeesOptionsV2();
  const tradeOutletsOptions = useTradeOutletsOptionsV2();

  const filtersConfig = [
    {
      field: "id",
      type: FilterType.Multiple,
      entityLabel: "Employees",
      options: employeesOptions.data,
    },
    {
      field: "tradeOutletId",
      type: FilterType.Multiple,
      entityLabel: "Stores",
      options: tradeOutletsOptions.data,
    },
  ];

  const { filterState, handleFilterChange, handleFiltersReset } =
    useTableFilters<GetEmployeesItem & { tradeOutletId: string }>(
      filtersConfig,
    );

  const employees = useEmployees({
    limit: pagination.currentLimit,
    offset: pagination.offset,
    id_in: filterState.id,
    trade_outlet_id_in: filterState.tradeOutletId,
    order_by:
      sortingState?.id as bnplMerchants.GetApiMerchantsServiceEmployeesOrderBy,
    search_text: searchValue,
  });

  const handleRowSelect = (row: Row<GetEmployeesItem>) => {
    setSelectedRowId(row.id);
  };

  const resetRowSelection = () => {
    if (employees.data?.items?.[0]) {
      setSelectedRowId(employees.data?.items[0].id);
    }
  };

  const handleCreateFormSubmit = (
    formData: EmployeeFormDataWithTradeOutlets,
  ) => {
    createEmployee.mutate(
      {
        payload: {
          name: formData.name,
          email: formData.email,
          phone: parseLocalPhoneNumber(formData.phone),
          role: bnplMerchants.CreateEmployeeExcludeMerchantIdRole.employee,
        },
      },
      {
        onError: handleEmployeeMutationError,
        onSuccess: ({ id }) => {
          setSelectedRowId(id);
          if (formData.tradeOutletsAccessIds) {
            const { bindIds, detachIds } = getEmployeeOutletsPayload(
              formData.tradeOutletsAccessIds,
            );
            updateEmployeeOutlets.mutate(
              {
                employeeId: id!,
                bindIds,
                detachIds,
              },
              {
                onSuccess: addEmployeeDialog.off,
              },
            );
          }

          showSnackbarSuccess("New employee added");
        },
      },
    );
  };

  const handleDelete = () => {
    deleteEmployee.mutate(
      {
        employeeId: selectedEmployee?.id!,
      },
      {
        onSuccess: () => {
          resetRowSelection();
          deleteEmployeeDialog.off();

          showSnackbarSuccess("Employee deleted");
        },
      },
    );
  };

  const handleEditFormSubmit = (formData: EmployeeFormDataWithTradeOutlets) => {
    updateEmployee.mutate(
      {
        employeeId: selectedEmployee?.id!,
        payload: {
          name: formData.name,
          email: formData.email,
          phone: parseLocalPhoneNumber(formData.phone),
        },
      },
      {
        onError: handleEmployeeMutationError,
        onSuccess: ({ id }) => {
          if (formData.tradeOutletsAccessIds) {
            const { bindIds, detachIds } = getEmployeeOutletsPayload(
              formData.tradeOutletsAccessIds,
            );
            updateEmployeeOutlets.mutate(
              {
                employeeId: id!,
                bindIds,
                detachIds,
              },
              {
                onSuccess: editEmployeeDialog.off,
              },
            );
          }

          showSnackbarSuccess("Changes saved");
        },
      },
    );
  };

  useEffect(() => {
    if (
      selectedRowId === undefined &&
      employees.data?.items?.length &&
      employees.data?.items[0]
    ) {
      setSelectedRowId(employees.data?.items[0].id);
    }
  }, [selectedRowId, employees.data?.items]);

  const selectedEmployee = useMemo(
    () => employees.data?.items?.find((item) => item.id === selectedRowId),
    [selectedRowId, employees?.data?.items],
  );

  const getRowId = (row: GetEmployeesItem) => row.id!;

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2.5}
      >
        <Typography variant="headlineH1">Employees</Typography>

        <Stack direction="row" spacing={1.5}>
          <SearchInput
            onClear={handleSearchClear}
            onSubmit={handleSearchSubmit}
            defaultValue={searchValue}
          />

          <Button onClick={addEmployeeDialog.on} endIcon={<IconAdd />}>
            Add Employee
          </Button>
        </Stack>
      </Stack>

      <Stack direction="row" spacing={2}>
        <BaseTableWrapper sx={{ height: 560, flex: 1 }}>
          <BaseTable
            columns={COLUMNS}
            data={employees.data?.items || []}
            sortingState={sortingState}
            onSortingChange={handleSortingChange}
            sortingOptions={SORTING_OPTIONS}
            filters={filtersConfig}
            filterState={filterState}
            onFilterChange={handleFilterChange}
            onFiltersReset={handleFiltersReset}
            isDataLoading={employees.isLoading}
            selectedRowId={selectedRowId}
            onRowSelect={handleRowSelect}
            getRowId={getRowId}
            deletedColumnId="archived"
          />
          <BaseTablePagination
            state={{
              ...pagination,
              totalCount: employees.data?.pagination?.total,
            }}
          />
        </BaseTableWrapper>

        <Card sx={{ width: 480, height: 560 }}>
          <EmployeeDetails
            onDeleteClick={deleteEmployeeDialog.on}
            onEditClick={editEmployeeDialog.on}
            employee={selectedEmployee}
            isLoading={
              createEmployee.isPending ||
              updateEmployee.isPending ||
              deleteEmployee.isPending ||
              updateEmployeeOutlets.isPending
            }
          />
        </Card>
      </Stack>

      <LoadingOverlay open={employees.isLoading} />

      <BaseDialog
        isOpen={addEmployeeDialog.value}
        onClose={addEmployeeDialog.off}
        title="Add employee"
      >
        <EmployeeForm
          onClose={addEmployeeDialog.off}
          tradeOutletsOptions={tradeOutletsOptions.data || []}
          onSubmit={handleCreateFormSubmit}
        />
      </BaseDialog>

      <BaseDialog
        isOpen={editEmployeeDialog.value}
        onClose={editEmployeeDialog.off}
        title="Edit employee"
      >
        <EmployeeForm
          onClose={editEmployeeDialog.off}
          initialData={selectedEmployee}
          tradeOutletsOptions={tradeOutletsOptions.data || []}
          onSubmit={handleEditFormSubmit}
        />
      </BaseDialog>

      <BaseDialog
        isOpen={deleteEmployeeDialog.value}
        onClose={deleteEmployeeDialog.off}
        title="Delete employee"
      >
        <Box p={2.5}>
          <Typography variant="regularTextRegular" color="neutral.50">
            Are you sure you want to remove {selectedEmployee?.name}?
          </Typography>
          <Typography mt={0.5} variant="regularTextRegular" color="neutral.50">
            This action cannot be undone.
          </Typography>

          <Stack direction="row" spacing={1} mt={2}>
            <Button
              onClick={deleteEmployeeDialog.off}
              variant="secondary"
              fullWidth
            >
              Close
            </Button>
            <Button onClick={handleDelete} fullWidth>
              Delete employee
            </Button>
          </Stack>
        </Box>
      </BaseDialog>
    </>
  );
};
