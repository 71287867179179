import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

export const fromNow = (
  value?: string | number | dayjs.Dayjs | Date | null,
  withoutSuffix?: boolean,
) => {
  return dayjs(value).fromNow(withoutSuffix);
};
