import { useQuery } from "@tanstack/react-query";
import { QUERY_KEY_SETTLEMENT_ORDERS } from "entities/settlement-orders";
import { getSettlemenOrders, GetSettlementOrdersParams } from "shared/api";

export const useSettlementOrders = ({
  settlementId,
  params,
}: GetSettlementOrdersParams) =>
  useQuery({
    queryFn: () => getSettlemenOrders({ settlementId, params }),
    queryKey: [QUERY_KEY_SETTLEMENT_ORDERS, settlementId, params],
    enabled: Boolean(settlementId),
  });
