// TODO: pluralization
// TODO: share with personal account
export const getCountdown = (seconds: number) => {
  const minutes = seconds / 60;
  const hours = minutes / 60;

  if (hours >= 1) {
    return `${hours} hours`;
  }

  if (minutes >= 1) {
    return `${minutes} minutes`;
  }

  return `${seconds} seconds`;
};
