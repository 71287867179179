import { config } from "shared/lib";
import { Typography } from "shared/ui";
import { LayoutMain } from "widgets/layout-main";

export const PageAccessDenied = () => {
  return (
    <LayoutMain>
      <Typography align="center" variant="headlineH1">
        No access
      </Typography>
      <Typography align="center" color="neutral.50" mt={1}>
        If you still have questions, please contact
        <Typography color="primary">{config.project.supportEmail}</Typography>
      </Typography>
    </LayoutMain>
  );
};
