import { useBoolean } from "@mychili/ui-web";
import { useEmployee } from "entities/employees";
import { ProfileForm } from "features/update-profile";
import {
  BaseDialog,
  Button,
  Card,
  IconCall,
  IconMail,
  Loader,
  Stack,
  Typography,
} from "shared/ui";
import { LayoutMain } from "widgets/layout-main";

export const PageProfile = () => {
  const employee = useEmployee();

  const editDialog = useBoolean();

  if (!employee?.data) return <Loader />;

  return (
    <LayoutMain>
      <Card
        sx={{
          p: 3,
          width: 500,
          marginX: "auto",
        }}
      >
        <Typography variant="headlineH3">{employee.data.name}</Typography>

        <Stack mt={2.5} direction="row" spacing={2.5} alignItems="center">
          <Stack direction="row" spacing={1.5}>
            <IconCall sx={{ color: "neutral.70" }} />
            <Typography variant="smallTextRegular" color="neutral.20">
              {employee.data.phone}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={1.5}>
            <IconMail sx={{ color: "neutral.70" }} />
            <Typography variant="smallTextRegular" color="neutral.20">
              {employee.data.email}
            </Typography>
          </Stack>
        </Stack>

        <Button
          onClick={editDialog.on}
          sx={{
            mt: 2.5,
            width: 214,
          }}
          variant="secondary"
        >
          Change information
        </Button>
      </Card>

      <BaseDialog
        title="Editing profile"
        isOpen={editDialog.value}
        onClose={editDialog.off}
      >
        <ProfileForm onBackClick={editDialog.off} />
      </BaseDialog>
    </LayoutMain>
  );
};
