import { useQuery } from "@tanstack/react-query";
import { getOrdersStatuses } from "shared/api/orders/get-orders-statuses";

import { QUERY_KEY_ORDERS_STATUSES } from "../../lib";

export const useOrdersStatuses = () =>
  useQuery({
    queryFn: getOrdersStatuses,
    queryKey: QUERY_KEY_ORDERS_STATUSES,
  });
