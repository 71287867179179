import { useEffect, useState } from "react";
import { InputCode, InputCodeProps } from "shared/ui";

export const EnterCodeInput = ({
  onConfirm,
  ...props
}: Omit<InputCodeProps, "onChange" | "value"> & {
  onConfirm: (code: string) => void;
}) => {
  const [code, setCode] = useState("");

  useEffect(() => {
    if (code.length === 4) {
      onConfirm(code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  const handleChange = (value: string) => {
    setCode(value);
  };

  return <InputCode onChange={handleChange} value={code} {...props} />;
};
