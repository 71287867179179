import { useState } from "react";

const DEFAULT_LIMIT = 50;

export const useTablePaginationState = (limit = DEFAULT_LIMIT) => {
  const [page, setPage] = useState(0);
  const [currentLimit, setCurrentLimit] = useState(limit);
  const [totalCount, setTotalCount] = useState(0);

  const currentPage = page > 0 ? page - 1 : 0;

  // Pagination handlers
  const handleCurrentPageChange = (value: number) => {
    setPage(value + 1);
  };

  const handlePageSizeUpdate = (value: number) => {
    setPage(1);
    setCurrentLimit(value);
  };

  const handlePaginationReset = () => {
    setPage(0);
  };

  return {
    offset: currentPage * currentLimit,
    currentLimit,
    currentPage,
    handleCurrentPageChange,
    handlePageSizeUpdate,
    handlePaginationReset,
    totalCount,
    setTotalCount,
  };
};
