import { TradeOutletsAccessState } from "features/employee-form";

type EmployeeOutletsPayload = {
  bindIds: string[];
  detachIds: string[];
};

export const getEmployeeOutletsPayload = (
  tradeOutletsIds: TradeOutletsAccessState,
): EmployeeOutletsPayload => {
  const bindIds: string[] = [];
  const detachIds: string[] = [];

  for (const [id, checked] of Object.entries(tradeOutletsIds)) {
    if (checked) {
      bindIds.push(id);
    } else {
      detachIds.push(id);
    }
  }

  return {
    bindIds,
    detachIds,
  };
};
