import { bnplMerchants } from "@mychili/frontend-libs-api-core";

export type GetOrdersStatusesResponseData =
  | bnplMerchants.GetOrdersStatuses["statuses"]
  | undefined;

export const getOrdersStatuses =
  async (): Promise<GetOrdersStatusesResponseData> => {
    const response = await bnplMerchants.getApiMerchantsServiceOrdersStatuses();

    if (!response.data.data?.statuses) {
      throw new Error("Error getting trade outlets names names");
    }

    return response.data.data.statuses;
  };
