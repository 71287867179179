import { config } from "shared/lib";

export const parseLocalPhoneNumber = (s: string) => {
  // Будет работать, пока в маске нет других разделителей, кроме пробелов
  const raw = s.replace(/\s/g, "");

  if (raw.startsWith(config.country.phonePrefixInternational)) {
    return raw;
  }

  const startsWithPrefixNationalRegex = new RegExp(
    `^${config.country.phonePrefixNational}`,
  );

  if (raw.startsWith(config.country.phonePrefixNational)) {
    return raw.replace(
      startsWithPrefixNationalRegex,
      config.country.phonePrefixInternational,
    );
  }

  return "";
};

export const parseInternationalPhoneNumber = (s: string) =>
  // @todo refactor to regexp
  s?.substring(0, 3) === config.country.phonePrefixInternational
    ? s.replace(
        config.country.phonePrefixInternational,
        config.country.phonePrefixNational,
      )
    : "";
