export type ExportSettlementFileExtension = "pdf" | "xlsx";

export const downloadSettlement = (
  blob: unknown,
  fileExtension: ExportSettlementFileExtension,
) => {
  if (!(blob instanceof Blob)) return;

  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `settlement.${fileExtension}`);
  document.body.appendChild(link);
  link.click();
};
