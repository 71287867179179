import { bnplMerchants } from "@mychili/frontend-libs-api-core";

export type GetTradeOutletsResponseData = {
  items: GetTradeOutletsItem[] | undefined;
  pagination: bnplMerchants.Pagination | undefined;
};

export type GetTradeOutletsItem = {
  agreementId: bnplMerchants.TradeOutletMany["agreement_id"];
  archived: bnplMerchants.TradeOutletMany["archived"];
  created: Date | undefined;
  id: bnplMerchants.TradeOutletMany["id"];
  isOnline: bnplMerchants.TradeOutletMany["is_online"];
  location: bnplMerchants.TradeOutletMany["location"];
  locationGeo: bnplMerchants.TradeOutletMany["location_geo"];
  logoUrl: bnplMerchants.TradeOutletMany["logo_url"];
  merchantId: bnplMerchants.TradeOutletMany["merchant_id"];
  name: bnplMerchants.TradeOutletMany["name"];
  newOrderLink: bnplMerchants.TradeOutletMany["new_order_link"];
  updated: Date | undefined;
};

export const getTradeOutlets = async (
  options: bnplMerchants.GetApiMerchantsServiceTradeOutletsParams,
): Promise<GetTradeOutletsResponseData> => {
  const response =
    await bnplMerchants.getApiMerchantsServiceTradeOutlets(options);

  return {
    items: response.data.data?.items?.map((i) => ({
      agreementId: i.agreement_id,
      archived: i.archived,
      created: i.created ? new Date(i.created) : undefined,
      id: i.id,
      isOnline: i.is_online,
      location: i.location,
      locationGeo: i.location_geo,
      logoUrl: i.logo_url,
      merchantId: i.merchant_id,
      name: i.name,
      newOrderLink: i.new_order_link,
      updated: i.updated ? new Date(i.updated) : undefined,
    })),
    pagination: response.data.data?.pagination,
  };
};
