import { useEmployeeOutlets } from "entities/employee-outlets";
import { getOrdersPath } from "entities/orders";
import { useNavigate } from "react-router-dom";
import { GetEmployeesItem } from "shared/api";
import {
  ActionButton,
  Box,
  Button,
  CheckboxButton,
  IconBox1,
  IconChevronRight,
  Loader,
  Stack,
  Typography,
} from "shared/ui";

type EmployeeDetailsProps = {
  onDeleteClick: () => void;
  onEditClick: () => void;
  employee?: GetEmployeesItem;
  isLoading?: boolean;
};

export const EmployeeDetails = ({
  employee,
  onDeleteClick,
  onEditClick,
  isLoading,
}: EmployeeDetailsProps) => {
  const navigate = useNavigate();

  const employeeOutlets = useEmployeeOutlets({
    enabled: Boolean(employee?.id) && !isLoading,
    variables: {
      employee_id: employee?.id,
    },
  });

  if (!employee) return null;

  if (isLoading || employeeOutlets.isLoading) return <Loader />;

  const handleAllOrdersClick = () => {
    navigate(getOrdersPath({ employeeIdIn: employee.id }));
  };

  return (
    <Stack height="100%">
      <Typography p={2.5} color="neutral.20" variant="bigTextSemiBold">
        {employee.name} {employee.archived ? "<deleted>" : ""}
      </Typography>

      <Box
        p={2.5}
        pb={5.25}
        sx={{
          borderTop: "1px solid",
          borderColor: "neutral.95",
          overflowY: "auto",
        }}
      >
        <Typography mb={2} variant="smallTextMedium">
          Orders
        </Typography>

        <ActionButton
          onClick={handleAllOrdersClick}
          color="gray"
          text="Employee's all orders"
          startIcon={<IconBox1 />}
          endIcon={<IconChevronRight />}
        />

        <Typography mt={2.5} mb={2} variant="smallTextMedium">
          Access to stores
        </Typography>

        <Stack spacing={1}>
          {employeeOutlets.data?.items?.map(
            ({ id, tradeOutletName, tradeOutletLocation }) => (
              <CheckboxButton
                key={id}
                checked
                text={tradeOutletName}
                description={tradeOutletLocation ?? ""}
                color="gray"
                disabled
              />
            ),
          )}
        </Stack>
      </Box>

      <Stack
        direction="row"
        spacing={1}
        p={2.5}
        width="100%"
        marginTop="auto"
        sx={{
          boxShadow:
            // @TODO Add to ui-web after appearing in DS
            "-1px 0px 8px 0px rgba(65, 69, 77, 0.02), 4px 0px 8px 0px rgba(65, 69, 77, 0.04)",
          borderTop: "1px solid",
          borderColor: "neutral.95",
        }}
      >
        {employee.archived ? (
          <Button disabled fullWidth>
            Employee is deleted
          </Button>
        ) : (
          <>
            <Button onClick={onDeleteClick} variant="secondary" fullWidth>
              Delete employee
            </Button>
            <Button onClick={onEditClick} variant="primary" fullWidth>
              Change information
            </Button>
          </>
        )}
      </Stack>
    </Stack>
  );
};
