import { useMutation } from "@tanstack/react-query";
import { queryClient } from "app/providers";
import { QUERY_KEY_EMPLOYEE } from "entities/employees";
import { updateEmployee } from "shared/api";

export const useUpdateProfile = () =>
  useMutation({
    mutationFn: updateEmployee,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEY_EMPLOYEE });
    },
  });
