import { bnplMerchants } from "@mychili/frontend-libs-api-core";
import { enqueueSnackbar } from "@mychili/ui-web";

export type GetEmployeeOutletsItem = {
  id: bnplMerchants.GetTradeOutletsResponseMany["id"];
  archived: bnplMerchants.GetTradeOutletsResponseMany["archived"];
  created: bnplMerchants.GetTradeOutletsResponseMany["created"];
  employeeId: bnplMerchants.GetTradeOutletsResponseMany["employee_id"];
  tradeOutletId: bnplMerchants.GetTradeOutletsResponseMany["trade_outlet_id"];
  employeeName: bnplMerchants.GetTradeOutletsResponseMany["employee_name"];
  tradeOutletName: bnplMerchants.GetTradeOutletsResponseMany["trade_outlet_name"];
  tradeOutletLocation: bnplMerchants.GetTradeOutletsResponseMany["trade_outlet_location"];
};

type GetEmployeeOutletsResponseData = {
  items: GetEmployeeOutletsItem[] | undefined;
  pagination: bnplMerchants.Pagination | undefined;
};

export const getEmployeeOutlets = async (
  options: bnplMerchants.GetApiMerchantsServiceEmployeeOutletsParams,
): Promise<GetEmployeeOutletsResponseData> => {
  const response =
    await bnplMerchants.getApiMerchantsServiceEmployeeOutlets(options);

  if (!response.data.data) {
    enqueueSnackbar("Error getting employee outlets", { variant: "error" });
    return { items: undefined, pagination: undefined };
  }

  return {
    items: response.data.data?.items?.map((i) => ({
      id: i.id,
      archived: i.archived,
      created: i.created,
      employeeId: i.employee_id,
      tradeOutletId: i.trade_outlet_id,
      employeeName: i.employee_name,
      tradeOutletName: i.trade_outlet_name,
      tradeOutletLocation: i.trade_outlet_location,
    })),
    pagination: response.data.data?.pagination,
  };
};
