import { useUser } from "entities/user";
import { useLogout } from "features/logout";
import { NavLink, useNavigate } from "react-router-dom";
import { ROUTES } from "shared/routing";
import {
  BaseTooltip,
  IconAccount,
  IconButton,
  IconLogout,
  IconQuestion,
  Show,
  Stack,
  Typography,
} from "shared/ui";

import { NAVIGATION_ITEMS } from "../constants";

// @TODO Move to country config
const SUPPORT_URL = "https://merchant-help.ahapay.my/hc/en-my";

export const Navigation = () => {
  const user = useUser();

  const isAdmin = user.data?.role === "admin";

  const logout = useLogout();
  const navigate = useNavigate();

  const handleProfileClick = () => {
    navigate(ROUTES.profile);
  };

  const handleSupportClick = () => {
    window.location.href = SUPPORT_URL;
  };

  const handleLogoutClick = () => {
    logout.mutate(undefined, {
      onSuccess: () => navigate(ROUTES.login),
    });
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        alignSelf="self-start"
        justifyContent="space-between"
        flex={1}
      >
        <Stack direction="row" spacing={4}>
          {isAdmin
            ? NAVIGATION_ITEMS.map((item) => (
                <NavLink
                  key={item.route}
                  to={item.route}
                  style={{ textDecoration: "none" }}
                >
                  {({ isActive }) => (
                    <Typography
                      color={isActive ? "primary" : "neutral.20"}
                      variant="regularTextRegular"
                    >
                      {item.title}
                    </Typography>
                  )}
                </NavLink>
              ))
            : []}
        </Stack>

        <Stack direction="row" gap={1} alignItems="center">
          <Show when={isAdmin}>
            <BaseTooltip title="Profile" placement="left">
              <NavLink key={ROUTES.profile} to={ROUTES.profile}>
                {({ isActive }) => (
                  <IconButton
                    onClick={handleProfileClick}
                    size="small"
                    color="transparent"
                  >
                    <IconAccount
                      sx={{
                        color: isActive ? "common.primary" : "neutral.20",
                      }}
                    />
                  </IconButton>
                )}
              </NavLink>
            </BaseTooltip>

            <BaseTooltip title="Support" placement="left">
              <div>
                <IconButton
                  onClick={handleSupportClick}
                  size="small"
                  color="transparent"
                >
                  <IconQuestion sx={{ color: "neutral.20" }} />
                </IconButton>
              </div>
            </BaseTooltip>
          </Show>

          <BaseTooltip title="Log out" placement="left">
            <div>
              <IconButton
                onClick={handleLogoutClick}
                size="small"
                color="transparent"
              >
                <IconLogout sx={{ color: "neutral.20" }} />
              </IconButton>
            </div>
          </BaseTooltip>
        </Stack>
      </Stack>
    </>
  );
};
