import { ColumnDef } from "@tanstack/react-table";
import { GetTradeOutletsItem } from "shared/api";
import { SortingOptions } from "shared/ui";

export const COLUMNS: ColumnDef<GetTradeOutletsItem, any>[] = [
  {
    accessorKey: "name",
    header: "Store",
  },
  {
    accessorKey: "location",
    header: "Address",
  },
  {
    accessorKey: "archived",
  },
];

export const SORTING_OPTIONS: SortingOptions<GetTradeOutletsItem> = {
  name: [
    {
      name: "A-Z",
      id: "name",
    },
    {
      name: "Z-A",
      id: "-name",
    },
  ],
  location: [
    {
      name: "A-Z",
      id: "location",
    },
    {
      name: "Z-A",
      id: "-location",
    },
  ],
};
