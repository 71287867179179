import { useMutation } from "@tanstack/react-query";
import { exportSettlement } from "shared/api";

import { downloadSettlement, ExportSettlementFileExtension } from "../../lib";

type UseExportSettlementParams = {
  fileFormat: ExportSettlementFileExtension;
};

export const useExportSettlement = ({
  fileFormat,
}: UseExportSettlementParams) =>
  useMutation({
    mutationFn: exportSettlement,
    onSuccess: (data) => {
      downloadSettlement(data, fileFormat);
    },
  });
