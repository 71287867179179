import { config } from "shared/lib";
import * as yup from "yup";

export const employeeSchema = yup.object().shape({
  name: yup
    .string()
    .required("This field is required")
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
      "The name must contain only Latin letters",
    ),
  phone: yup
    .string()
    .required("This field is required")
    .transform((value) => value.replace(/\s/g, ""))
    .length(
      config.country.phoneNumberLength,
      `The phone number must contain ${config.country.phoneNumberLength} digits. Example: ${config.country.phoneExample}`,
    ),
  email: yup
    .string()
    .required("This field is required")
    .email("Invalid email address. Accepted format: example@mail.com"),
});
