import { TradeOutletFormData } from "features/trade-outlet-form";
import { GetTradeOutletsItem } from "shared/api";

export const getEditFormInitialData = ({
  name,
  location,
  logoUrl,
}: GetTradeOutletsItem): TradeOutletFormData => ({
  name,
  location: location ?? "",
  logoUrl: logoUrl ?? "",
});
