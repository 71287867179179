import { forwardRef } from "react";

import { Box, BoxProps } from "../box";

export const Container = forwardRef<HTMLDivElement, BoxProps>(
  ({ children }, ref) => (
    <Box width="1280px" mx="auto" px={2.5} ref={ref}>
      {children}
    </Box>
  ),
);
