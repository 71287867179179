import { bnplMerchants } from "@mychili/frontend-libs-api-core";

export type RefreshSessionRequestParams = {
  accessToken: bnplMerchants.RefreshSessionRequest["access_token"];
  appVersion: bnplMerchants.RefreshSessionRequest["app_version"];
  refreshToken: bnplMerchants.RefreshSessionRequest["refresh_token"];
};

export type RefreshSessionResponseData = {
  accessToken: bnplMerchants.ConfirmCodeResponse["access_token"];
  refreshToken: bnplMerchants.ConfirmCodeResponse["refresh_token"];
};

export const refreshSession = async (
  params: RefreshSessionRequestParams,
): Promise<RefreshSessionResponseData> => {
  const response =
    await bnplMerchants.postApiMerchantsServiceAuthRefreshSession({
      access_token: params.accessToken,
      app_version: params.appVersion,
      refresh_token: params.refreshToken,
    });

  return {
    accessToken: response.data.data?.access_token,
    refreshToken: response.data.data?.refresh_token,
  };
};
