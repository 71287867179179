import { useInvalidateTradeOutlets } from "entities/trade-outlets";
import { GetTradeOutletsItem } from "shared/api";
import { showSnackbarSuccess } from "shared/lib";
import { Box, Button, Stack, Typography } from "shared/ui";

import { useDeleteTradeOutlet } from "../model";

type DeleteTradeOutletProps = {
  tradeOutlet?: GetTradeOutletsItem;
  onClose: () => void;
  onDelete: () => void;
};

export const DeleteTradeOutlet = ({
  tradeOutlet,
  onClose,
  onDelete,
}: DeleteTradeOutletProps) => {
  const invalidateTradeOutlets = useInvalidateTradeOutlets();
  const deleteTradeOutlet = useDeleteTradeOutlet();

  const handleDelete = () => {
    if (tradeOutlet?.id) {
      deleteTradeOutlet.mutate(
        {
          tradeOutletId: tradeOutlet?.id,
        },
        {
          onSuccess: () => {
            invalidateTradeOutlets();
            onClose();
            onDelete();

            showSnackbarSuccess("Store deleted");
          },
        },
      );
    }
  };

  return (
    <Box p={2.5}>
      <Typography color="neutral.50" variant="regularTextRegular">
        Are you sure you want to delete {tradeOutlet?.name}?
      </Typography>
      <Typography mt={0.5} color="neutral.50" variant="regularTextRegular">
        This action cannot be undone.
      </Typography>

      <Stack direction="row" spacing={1} width="100%" mt={2}>
        <Button onClick={onClose} variant="secondary" fullWidth>
          Close
        </Button>
        <Button onClick={handleDelete} variant="primary" fullWidth>
          Delete store
        </Button>
      </Stack>
    </Box>
  );
};
