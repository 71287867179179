import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEY_EMPLOYEE_OUTLETS } from "entities/employee-outlets";
import { QUERY_KEY_EMPLOYEES } from "entities/employees";
import { updateEmployeeOutlets } from "shared/api";

export const useUpdateEmployeeOutlets = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateEmployeeOutlets,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY_EMPLOYEES,
      });
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY_EMPLOYEE_OUTLETS,
      });
    },
  });
};
