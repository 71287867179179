import { bnplMerchants } from "@mychili/frontend-libs-api-core";

export type ConfirmCodeRequestParams = {
  appVersion: bnplMerchants.ConfirmCodeRequest["app_version"];
  authId: bnplMerchants.ConfirmCodeRequest["auth_id"];
  code: bnplMerchants.ConfirmCodeRequest["code"];
};

export type ConfirmCodeResponseData = {
  accessToken: bnplMerchants.ConfirmCodeResponse["access_token"];
  refreshToken: bnplMerchants.ConfirmCodeResponse["refresh_token"];
};

export const confirmCode = async (
  params: ConfirmCodeRequestParams,
): Promise<ConfirmCodeResponseData> => {
  const response = await bnplMerchants.postApiMerchantsServiceAuthConfirmCode({
    app_version: params.appVersion,
    auth_id: params.authId,
    code: params.code,
  });

  return {
    accessToken: response.data.data?.access_token,
    refreshToken: response.data.data?.refresh_token,
  };
};
