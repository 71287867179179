import { useSettlementOrders } from "entities/settlement-orders";
import {
  formatSettlementNumber,
  useExportSettlement,
} from "entities/settlements";
import { useState } from "react";
import { GetSettlementOrdersItem, GetSettlementsItem } from "shared/api";
import {
  formatDate,
  showSnackbarError,
  useTablePaginationState,
} from "shared/lib";
import {
  BaseLoader,
  BaseTable,
  BaseTablePagination,
  BaseTableWrapper,
  BaseTooltip,
  Box,
  IconArticle,
  IconButton,
  IconCalendar,
  IconCalendarCheck,
  IconDownload,
  IconPerson,
  IconToFill,
  InfoBlock,
  Loader,
  MenuItem,
  Popover,
  Stack,
  Typography,
} from "shared/ui";

import { COLUMNS } from "../lib";

export type SettlementDetailsProps = {
  settlement?: GetSettlementsItem;
};

export const SettlementDetails = ({ settlement }: SettlementDetailsProps) => {
  const pagination = useTablePaginationState();

  const exportSettlementToPdf = useExportSettlement({ fileFormat: "pdf" });
  const exportSettlementToXlsx = useExportSettlement({ fileFormat: "xlsx" });

  const orders = useSettlementOrders({
    settlementId: settlement?.id,
    params: {
      limit: pagination.currentLimit,
      offset: pagination.offset,
    },
  });

  const [downloadButtonAnchorEl, setDownloadButtonAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  const handlePopoverClose = () => setDownloadButtonAnchorEl(null);

  if (!settlement) return null;

  const handleDownloadButtonClick = (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setDownloadButtonAnchorEl(e.currentTarget);
  };

  const handlePDFExport = () => {
    exportSettlementToPdf.mutate(
      {
        settlementId: settlement.id!,
        params: { file_format: "pdf" },
      },
      {
        onError: () =>
          showSnackbarError("Error exporting settlement data to PDF"),
      },
    );
  };

  const handleXLSXExport = () => {
    exportSettlementToXlsx.mutate(
      {
        settlementId: settlement.id!,
        params: { file_format: "xlsx" },
      },
      {
        onError: () =>
          showSnackbarError("Error exporting settlement data to XLSX"),
      },
    );
  };

  const getRowId = (row: GetSettlementOrdersItem) => row.id!;

  if (orders.isLoading) return <Loader />;

  const payoutPeriod =
    settlement.periodFrom && settlement.periodTo
      ? `${formatDate(settlement.periodFrom, "DD/MM/YYYY")} - ${formatDate(settlement.periodTo, "DD/MM/YYYY")}`
      : settlement.periodTo
        ? formatDate(settlement.periodTo, "DD/MM/YYYY")
        : "-";

  return (
    <Stack sx={{ height: "100%" }} spacing={2.5}>
      <Stack
        px={2.5}
        py={1.25}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        borderBottom="1px solid"
        borderColor="neutral.95"
      >
        <Typography color="neutral.20" variant="headlineH4">
          Payout ID {formatSettlementNumber(settlement.settlementNumber)}
        </Typography>

        <BaseTooltip title="Download documents" placement="bottom">
          <div>
            <IconButton
              size="medium"
              color="gray"
              onClick={handleDownloadButtonClick}
            >
              <IconDownload />
            </IconButton>
          </div>
        </BaseTooltip>
      </Stack>

      <Box
        sx={{
          px: 2.5,
        }}
      >
        <Stack spacing={1}>
          <Stack direction="row" spacing={1}>
            <InfoBlock
              title="Name"
              description={settlement.merchantName}
              Icon={IconPerson}
            />
            <InfoBlock
              title="Amount"
              description={String(settlement.salesAmount)}
              Icon={IconToFill}
            />
          </Stack>
          <Stack direction="row" spacing={1}>
            <InfoBlock
              title="Payment date"
              description={formatDate(settlement.paymentDate, "DD/MM/YYYY")}
              Icon={IconCalendarCheck}
            />
            <InfoBlock
              title="Payout period"
              description={payoutPeriod}
              Icon={IconCalendar}
            />
            <InfoBlock
              title="Created"
              description={formatDate(settlement.created, "DD/MM/YYYY")}
              Icon={IconArticle}
            />
          </Stack>
        </Stack>
      </Box>

      <Box sx={{ flex: 1 }}>
        <BaseTableWrapper sx={{ height: 390 }}>
          <BaseTable
            data={orders.data?.items || []}
            isDataLoading={orders.isLoading}
            columns={COLUMNS}
            getRowId={getRowId}
          />
          <BaseTablePagination
            state={{
              ...pagination,
              totalCount: orders.data?.pagination?.total,
            }}
          />
        </BaseTableWrapper>
      </Box>

      <Popover
        anchorEl={downloadButtonAnchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        onClose={handlePopoverClose}
        open={Boolean(downloadButtonAnchorEl)}
      >
        <Stack p={0.5}>
          <Stack direction="row" alignItems="center">
            <MenuItem
              disabled={exportSettlementToPdf.isPending}
              onClick={handlePDFExport}
            >
              Export PDF
            </MenuItem>
            {exportSettlementToPdf.isPending && <BaseLoader size={20} />}
          </Stack>
          <Stack direction="row" alignItems="center">
            <MenuItem
              disabled={exportSettlementToXlsx.isPending}
              onClick={handleXLSXExport}
            >
              Export XLS
            </MenuItem>
            {exportSettlementToXlsx.isPending && <BaseLoader size={20} />}
          </Stack>
        </Stack>
      </Popover>
    </Stack>
  );
};
