import { Box } from "@mui/material";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconSearch,
  Radio,
  RadioGroup,
} from "@mychili/ui-web";
import { useState } from "react";
import { Button, Input, Show, Stack, Typography } from "shared/ui";

export type OptionsDropdownProps = {
  title: string;
  type: "radio" | "check";
  onChange: (value: string | string[]) => void;
  // @TODO Make id required after backend types fix
  options: { id?: string; name: string }[];
  value: string | string[];
  showSearch?: boolean;
};

export const OptionsDropdown = ({
  options,
  title,
  type,
  value,
  onChange,
  showSearch = false,
}: OptionsDropdownProps) => {
  const [searchValue, setSearchValue] = useState("");

  const filteredOptions = options.filter((option) =>
    option.name.toLowerCase().includes(searchValue.toLowerCase()),
  );

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleCheckboxChange = (checkboxValue: string) => {
    if (!Array.isArray(value)) return;

    if (value.includes(checkboxValue))
      onChange(value.filter((item) => item !== checkboxValue));
    else onChange([...value, checkboxValue]);
  };

  const handleRadioGroupChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  const handleClearClick = () => {
    onChange([]);
  };

  return (
    <Stack
      sx={{
        backgroundColor: "neutral.96",
      }}
      p={0.5}
      boxShadow="0px 12px 30px 0px rgba(0, 0, 0, 0.10), 0px 7.704px 12.741px 0px rgba(0, 0, 0, 0.06), 0px 1.63px 3.259px 0px rgba(0, 0, 0, 0.06);"
      borderRadius={1}
      alignItems="flex-start"
      minWidth={240}
    >
      <Typography py={1.5} px={2} variant="smallTextMedium">
        {title}
      </Typography>

      <Show when={showSearch}>
        <Box px={2}>
          <Input
            onChange={handleSearchInputChange}
            placeholder="Search"
            InputProps={{
              startAdornment: <IconSearch />,
              sx: {
                // @TODO Remove after adding small inputs to DS
                ".MuiInputBase-input": {
                  cursor: "pointer",
                  px: 2,
                  py: 1.5,
                  fontSize: 14,
                  lineHeight: 21,
                },
              },
            }}
          />
        </Box>
      </Show>

      <Show when={type === "check"}>
        <Button onClick={handleClearClick} variant="link">
          Clear selected items
        </Button>
      </Show>

      <Box sx={{ overflowY: "auto", maxHeight: "308px", width: "100%" }}>
        {type === "radio" ? (
          <RadioGroup value={value} onChange={handleRadioGroupChange}>
            {filteredOptions.map((option) => (
              <FormControlLabel
                key={option.id}
                control={<Radio />}
                label={
                  <Typography variant="smallTextRegular">
                    {option.name}
                  </Typography>
                }
                value={option.id}
                sx={{
                  m: 0,
                  px: 1,
                  height: 56,
                }}
              />
            ))}
          </RadioGroup>
        ) : (
          <FormGroup>
            {filteredOptions.map((option) => (
              <FormControlLabel
                key={option.id}
                control={
                  <Checkbox
                    checked={value.includes(option.id!)}
                    onChange={() => handleCheckboxChange(option.id!)}
                  />
                }
                label={
                  <Typography variant="smallTextRegular">
                    {option.name}
                  </Typography>
                }
                sx={{
                  m: 0,
                  px: 1,
                  height: 56,
                }}
              />
            ))}
          </FormGroup>
        )}
      </Box>
    </Stack>
  );
};
