import { ActionButton, IconChevronRight, Stack } from "shared/ui";

import { SummaryItem } from "../types";

type BaseTableSummaryProps = {
  items: SummaryItem[];
};

export const BaseTableSummary = ({ items }: BaseTableSummaryProps) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      p={2.5}
      sx={{
        ".MuiPaper-root": {
          flex: 1,
          maxWidth: 230,
          ".MuiTypography-regularTextRegular": {
            fontSize: "18px",
            lineHeight: "24px",
            fontWeight: 600,
          },
          ".MuiTypography-smallTextRegular": {
            fontSize: "12px",
            lineHeight: "18px",
            fontWeight: 400,
          },
        },
      }}
    >
      {items.map(({ title, description, onClick, key }) => (
        <ActionButton
          key={key}
          text={title}
          description={description}
          onClick={onClick ?? undefined}
          endIcon={onClick ? <IconChevronRight /> : undefined}
          color="gray"
        />
      ))}
    </Stack>
  );
};
