import { bnplMerchants } from "@mychili/frontend-libs-api-core";
import { useBoolean } from "@mychili/ui-web";
import { Row } from "@tanstack/react-table";
import { useAgreements } from "entities/agreements";
import {
  useInvalidateTradeOutlets,
  useTradeOutlets,
} from "entities/trade-outlets";
import { useTradeOutletsOptionsV2 } from "entities/trade-outlets";
import { useCreateTradeOutlet } from "features/create-trade-outlet";
import { DeleteTradeOutlet } from "features/delete-trade-outlet";
import { TradeOutletDetails } from "features/trade-outlet-details";
import {
  TradeOutletForm,
  TradeOutletFormDataWithLogo,
} from "features/trade-outlet-form";
import { useUpdateTradeOutlet } from "features/update-trade-outlet";
import { useUploadTradeOutletLogo } from "features/upload-trade-outlet-logo";
import { useEffect, useMemo, useState } from "react";
import { GetTradeOutletsItem } from "shared/api";
import {
  showSnackbarError,
  showSnackbarSuccess,
  useTableFilters,
  useTablePagination,
  useTableSearch,
  useTableSorting,
} from "shared/lib";
import {
  BaseDialog,
  BaseTable,
  BaseTablePagination,
  BaseTableWrapper,
  Button,
  Card,
  FilterType,
  IconAdd,
  LoadingOverlay,
  SearchInput,
  Stack,
  Typography,
} from "shared/ui";

import { COLUMNS, getEditFormInitialData, SORTING_OPTIONS } from "../lib";

export const TradeOutletList = () => {
  const createDialog = useBoolean();
  const editDialog = useBoolean();
  const deleteDialog = useBoolean();

  const tradeOutletsOptions = useTradeOutletsOptionsV2();

  const invalidateTradeOutlets = useInvalidateTradeOutlets();

  const uploadTradeOutletLogo = useUploadTradeOutletLogo();
  const createTradeOutlet = useCreateTradeOutlet();
  const updateTradeOutlet = useUpdateTradeOutlet();
  const agreements = useAgreements();

  const pagination = useTablePagination();

  const { searchValue, handleSearchClear, handleSearchSubmit } =
    useTableSearch();

  const { handleSortingChange, sortingState } =
    useTableSorting<GetTradeOutletsItem>(SORTING_OPTIONS);

  const filtersConfig = [
    {
      field: "id",
      type: FilterType.Multiple,
      entityLabel: "Stores",
      options:
        tradeOutletsOptions.data?.map((item) => ({
          id: item.id,
          name: `${item.name}${item.location ? ` (${item.location})` : ""}`,
        })) || [],
    },
  ];

  const { filterState, handleFilterChange, handleFiltersReset } =
    useTableFilters<GetTradeOutletsItem>(filtersConfig);

  const handleRowSelect = (row: Row<GetTradeOutletsItem>) => {
    setSelectedRowId(row.id);
  };

  const handleResetRowSelection = () => {
    if (tradeOutlets.data?.items?.[0]) {
      setSelectedRowId(tradeOutlets.data?.items[0].id);
    }
  };

  const tradeOutlets = useTradeOutlets({
    limit: pagination.currentLimit,
    offset: pagination.offset,
    id_in: filterState.id,
    order_by:
      sortingState?.id as bnplMerchants.GetApiMerchantsServiceTradeOutletsOrderBy,
    search_text: searchValue,
  });

  const [selectedRowId, setSelectedRowId] = useState<string | null>();

  const handleCreateFormSubmit = (data: TradeOutletFormDataWithLogo) => {
    if (!agreements.data?.[0]) {
      showSnackbarError("No agreement found");
      return;
    }

    createTradeOutlet.mutate(
      {
        payload: {
          agreement_id: agreements.data?.[0].id!,
          name: data.name,
          location: data.location,
        },
      },
      {
        onError: () => {
          showSnackbarError("Error creating store");
        },
        onSuccess: (response) => {
          if (!response.id) return;

          setSelectedRowId(response.id);

          if (data.logo) {
            uploadTradeOutletLogo.mutate(
              {
                tradeOutletId: response.id,
                imageBlob: data.logo,
              },
              {
                onError: () => {
                  showSnackbarError("Error uploading store logo");
                },
                onSuccess: invalidateTradeOutlets,
              },
            );
          } else {
            invalidateTradeOutlets();
          }

          showSnackbarSuccess("Store added");

          createDialog.off();
        },
      },
    );
  };

  const handleUpdateFormSubmit = (data: TradeOutletFormDataWithLogo) => {
    if (!selectedRowId) return;

    updateTradeOutlet.mutate(
      {
        tradeOutletId: selectedRowId,
        payload: {
          name: data.name,
          location: data.location || undefined,
          logo_url: data.logo ? undefined : null,
        },
      },
      {
        onError: () => {
          showSnackbarError("Error updating store");
        },
        onSuccess: (response) => {
          if (data.logo && response.id) {
            uploadTradeOutletLogo.mutate(
              {
                tradeOutletId: response.id,
                imageBlob: data.logo,
              },
              {
                onError: () => {
                  showSnackbarError("Error uploading store logo");
                },
                onSuccess: invalidateTradeOutlets,
              },
            );
          } else {
            invalidateTradeOutlets();
          }
          showSnackbarSuccess("Changes saved");

          editDialog.off();
        },
      },
    );
  };

  useEffect(() => {
    if (
      selectedRowId === undefined &&
      tradeOutlets.data?.items?.length &&
      tradeOutlets.data?.items[0]
    ) {
      setSelectedRowId(tradeOutlets.data?.items[0].id);
    }
  }, [selectedRowId, tradeOutlets.data?.items]);

  const selectedRowData = useMemo(() => {
    if (!tradeOutlets.data?.items || !selectedRowId) return undefined;

    return tradeOutlets.data.items.find((item) => item.id === selectedRowId);
  }, [selectedRowId, tradeOutlets.data?.items]);

  const getRowId = (row: GetTradeOutletsItem) => row.id!;

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2.5}
      >
        <Typography variant="headlineH1">Stores</Typography>

        <Stack direction="row" spacing={1.5}>
          <SearchInput
            onClear={handleSearchClear}
            onSubmit={handleSearchSubmit}
            defaultValue={searchValue}
          />

          <Button onClick={createDialog.on} endIcon={<IconAdd />}>
            Add Store
          </Button>
        </Stack>
      </Stack>

      <Stack direction="row" spacing={2}>
        <BaseTableWrapper sx={{ height: 560, flex: 1 }}>
          <BaseTable
            columns={COLUMNS}
            data={tradeOutlets.data?.items || []}
            sortingState={sortingState}
            onSortingChange={handleSortingChange}
            sortingOptions={SORTING_OPTIONS}
            filters={filtersConfig}
            filterState={filterState}
            onFilterChange={handleFilterChange}
            onFiltersReset={handleFiltersReset}
            isDataLoading={tradeOutlets.isLoading}
            selectedRowId={selectedRowId}
            onRowSelect={handleRowSelect}
            getRowId={getRowId}
            deletedColumnId="archived"
          />
          <BaseTablePagination
            state={{
              ...pagination,
              totalCount: tradeOutlets.data?.pagination?.total,
            }}
          />
        </BaseTableWrapper>

        <Card sx={{ width: 480, height: 560 }}>
          <TradeOutletDetails
            onDeleteClick={deleteDialog.on}
            onEditClick={editDialog.on}
            tradeOutlet={selectedRowData}
          />
        </Card>
      </Stack>

      <LoadingOverlay open={tradeOutlets.isLoading} />

      <BaseDialog
        isOpen={createDialog.value}
        onClose={createDialog.off}
        title="Add store"
      >
        <TradeOutletForm
          onClose={createDialog.off}
          onSubmit={handleCreateFormSubmit}
        />
      </BaseDialog>

      <BaseDialog
        isOpen={editDialog.value}
        onClose={editDialog.off}
        title="Editing store"
      >
        <TradeOutletForm
          onClose={editDialog.off}
          initialData={
            selectedRowData
              ? getEditFormInitialData(selectedRowData)
              : undefined
          }
          onSubmit={handleUpdateFormSubmit}
        />
      </BaseDialog>

      <BaseDialog
        isOpen={deleteDialog.value}
        onClose={deleteDialog.off}
        title="Delete store"
      >
        <DeleteTradeOutlet
          onClose={deleteDialog.off}
          onDelete={handleResetRowSelection}
          tradeOutlet={selectedRowData}
        />
      </BaseDialog>
    </>
  );
};
