export const ROUTES = {
  main: "/",
  login: "/login",
  welcome: "/welcome",
  tradeOutlets: "/trade-outlets",
  employees: "/employees",
  orders: "/orders",
  accessDenied: "/access-denied",
  profile: "/profile",
  settlements: "/settlements",
  merchantInfo: "/merchant-info",
} as const;

export type Route = keyof typeof ROUTES;
