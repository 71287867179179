import { Button, Stack, Typography } from "shared/ui";

type NoResultsProps = {
  onFiltersReset?: () => void;
};

export const NoResults = ({ onFiltersReset }: NoResultsProps) => (
  <Stack height="100%" alignItems="center" justifyContent="center" px={2}>
    <Typography variant="headlineH4" color="neutral.20">
      No results found
    </Typography>
    <Typography mt={1} variant="regularTextRegular" color="neutral.70">
      Try a different search or clear the filters
    </Typography>
    <Button
      onClick={onFiltersReset ?? undefined}
      sx={{ mt: 2.5 }}
      variant="secondary"
    >
      Clear filters
    </Button>
  </Stack>
);
