import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEY_GET_MERCHANT } from "entities/merchant";
import { useCallback } from "react";

export const useInvalidateMerchant = () => {
  const queryClient = useQueryClient();

  const invalidateMerchant = useCallback(
    () => queryClient.invalidateQueries({ queryKey: QUERY_KEY_GET_MERCHANT }),
    [queryClient],
  );

  return invalidateMerchant;
};
