export type PaginationState = {
  offset: number;
  currentLimit: number;
  currentPage: number;
  handleCurrentPageChange: (value: number) => void;
  handlePageSizeUpdate: (value: number) => void;
  totalCount?: number;
  setTotalCount: React.Dispatch<React.SetStateAction<number>>;
  pageSizeOptions?: number[];
};

export type SortingOption = {
  id: string;
  name: string;
};

export type SortingOptions<T> = {
  [key in keyof T]?: SortingOption[];
};

export enum FilterType {
  Range = "range",
  Multiple = "multiple",
}

export type FilterItem = {
  field: string;
  type: FilterType;
  // @TODO Make id required after backend types update
  options?: { id?: string; name: string }[];
  entityLabel?: string;
};

export type SummaryItem = {
  key: string | number;
  title: string | number;
  description: string;
  onClick?: () => void;
};
