import { IconSearch } from "@mychili/ui-web";
import { useState } from "react";

import { CloseButton } from "../close-button";
import { IconButton } from "../icon-button";
import { Input, InputProps } from "../input";
import { Stack } from "../stack";

export type SearchInputProps = Omit<InputProps, "onSubmit"> & {
  onClear: () => void;
  onSubmit: (value: string) => void;
};

export const SearchInput = ({
  onClear,
  onSubmit,
  defaultValue,
  ...inputProps
}: SearchInputProps) => {
  const [value, setValue] = useState(defaultValue as string);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleSubmit = () => {
    onSubmit(value);
  };

  const handleClear = () => {
    setValue("");
    onClear();
  };

  const isEmpty = !value;

  return (
    <Stack alignItems="center" direction="row" spacing={0.5}>
      <Input
        sx={{
          width: 248,
        }}
        InputProps={{
          endAdornment: !isEmpty ? (
            <CloseButton onClick={handleClear} />
          ) : undefined,
          sx: {
            // @TODO Remove after adding small inputs to DS
            ".MuiInputBase-input": {
              cursor: "pointer",
              px: isEmpty ? undefined : 0,
              py: 1.5,
              fontSize: 14,
              lineHeight: 21,
            },
          },
        }}
        placeholder="Search"
        value={value}
        onChange={handleChange}
        {...inputProps}
      />

      <IconButton onClick={handleSubmit} color="white">
        <IconSearch />
      </IconButton>
    </Stack>
  );
};
