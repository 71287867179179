import { useNavigate } from "react-router-dom";
import { ROUTES } from "shared/routing";
import {
  Box,
  Button,
  IconInfo,
  IconOpenIn,
  Link,
  Stack,
  Typography,
} from "shared/ui";

// @TODO Move to country config
const MERCHANTS_SERVICE_URL = "https://ahapay.my/signup";
const SHOPPER_APP_URL = "https://ahapay.my";

export const PageWelcome = () => {
  const navigate = useNavigate();

  const handleLoginButtonClick = () => {
    navigate(ROUTES.login);
  };

  return (
    <Stack
      maxWidth="md"
      mx="auto"
      justifyContent="center"
      sx={{
        height: "100%",
      }}
    >
      <Box
        component="img"
        display="block"
        mb={2}
        mx="auto"
        maxWidth={245}
        src="https://static.ahapay.my/logos/full.svg"
      />
      <Typography
        align="center"
        color="neutral.50"
        mb={5}
        variant="bigTextRegular"
      >
        Merchant Portal
      </Typography>
      <Box mb={2}>
        <Button fullWidth={true} onClick={handleLoginButtonClick}>
          Login
        </Button>
      </Box>
      <Box maxWidth={250} mx="auto">
        <Stack direction="column">
          <Button
            color="neutral"
            component={Link}
            fullWidth={true}
            href={MERCHANTS_SERVICE_URL}
            size="medium"
            startIcon={<IconInfo />}
            sx={{
              justifyContent: "flex-start",
            }}
            // @ts-ignore TODO: fix props when provide component prop in ui-web
            target="_blank"
            variant="link"
          >
            New to service? Please read
          </Button>
          <Button
            color="neutral"
            component={Link}
            fullWidth={true}
            href={SHOPPER_APP_URL}
            size="medium"
            startIcon={<IconOpenIn />}
            sx={{
              justifyContent: "flex-start",
            }}
            // @ts-ignore TODO: fix props when provide component prop in ui-web
            target="_blank"
            variant="link"
          >
            Shopper's app
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
};
