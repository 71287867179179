import { CountryConfig } from "../../types";

const config: CountryConfig = {
  phoneExample: "011 1111 1190",
  phonePrefixInternational: "+60",
  phonePrefixNational: "0",
  phoneNumberLength: 11,
};

export default config;
