import { bnplMerchants } from "@mychili/frontend-libs-api-core";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEY_EMPLOYEES } from "entities/employees";
import { getEmployees } from "shared/api";

export const useEmployees = (
  options: bnplMerchants.GetApiMerchantsServiceEmployeesParams = {},
) =>
  useQuery({
    queryFn: () => getEmployees(options),
    queryKey: [QUERY_KEY_EMPLOYEES, options],
  });
