import { aha, Theme } from "@mychili/ui-web";
import { countryCode, projectCode, themeKey } from "shared/config";
import { CountryCode, ProjectCode, ThemeKey } from "shared/types";

import my from "./settings/countries/my";
import ahp from "./settings/projects/ahp";
import { CountryConfig, ProjectConfig } from "./types";

export const configByCountryCode: Record<CountryCode, CountryConfig> = {
  [CountryCode.MY]: my,
};

export const configByProjectCode: Record<ProjectCode, ProjectConfig> = {
  [ProjectCode.AHP]: ahp,
};

export const themesByKey: Record<ThemeKey, Theme> = {
  [ThemeKey.AhaPay]: aha,
};

export const config = {
  country: configByCountryCode[countryCode],
  project: configByProjectCode[projectCode],
  theme: themesByKey[themeKey],
};
