import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEY_GET_MERCHANT } from "entities/merchant/lib";
import { updateMerchant } from "shared/api";

export const useUpdateMerchant = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateMerchant,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEY_GET_MERCHANT });
    },
  });
};
