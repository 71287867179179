import { EmployeeList } from "widgets/employee-list";
import { LayoutMain } from "widgets/layout-main";

export const PageEmployees = () => {
  return (
    <LayoutMain>
      <EmployeeList />
    </LayoutMain>
  );
};
