import { useMutation } from "@tanstack/react-query";
import { uploadTradeOutletLogo } from "shared/api";

type UploadTradeOutletLogoParams = {
  tradeOutletId: string;
  imageBlob: Blob;
};

export const useUploadTradeOutletLogo = () =>
  useMutation({
    mutationFn: ({ tradeOutletId, imageBlob }: UploadTradeOutletLogoParams) =>
      uploadTradeOutletLogo(tradeOutletId, { logo: imageBlob }),
  });
