import { useGetCode } from "features/login";
import { useState } from "react";
import { parseLocalPhoneNumber } from "shared/lib";

import { ConfirmCodeView } from "../confirm-code-view";
import { EnterPhoneNumberView } from "../enter-phone-number-view";

export const Login = () => {
  const getCode = useGetCode();

  const countdown = getCode.data?.countdown;

  const [authId, setAuthId] = useState<string>();
  const [phoneNumber, setPhoneNumber] = useState<string>();

  const handleBack = () => {
    getCode.reset();
    setAuthId(undefined);
    setPhoneNumber(undefined);
  };

  const handlePhoneNumberSubmit = (values: { phoneNumber: string }) => {
    getCode.mutate(
      { phoneNumber: parseLocalPhoneNumber(values.phoneNumber) },
      {
        onSuccess: (data, variables) => {
          setAuthId(data.authId);
          setPhoneNumber(variables.phoneNumber);
        },
      },
    );
  };

  const handleCodeResend = (phoneNumberToResend: string) => () => {
    getCode.mutate(
      { phoneNumber: parseLocalPhoneNumber(phoneNumberToResend) },
      {
        onSuccess: (data, variables) => {
          setAuthId(data.authId);
          setPhoneNumber(variables.phoneNumber);
        },
      },
    );
  };

  if (authId && phoneNumber) {
    return (
      <ConfirmCodeView
        authId={authId}
        countdown={countdown}
        onBack={handleBack}
        onResend={handleCodeResend(phoneNumber)}
        phoneNumber={phoneNumber}
      />
    );
  }

  return (
    <EnterPhoneNumberView
      error={getCode.error}
      onSubmit={handlePhoneNumberSubmit}
    />
  );
};
