import { ColumnDef } from "@tanstack/react-table";
import { GetEmployeesItem } from "shared/api";
import { countryCode } from "shared/config";
import { FormatPhone, SortingOptions } from "shared/ui";

export const COLUMNS: ColumnDef<GetEmployeesItem, any>[] = [
  {
    header: "Employee",
    accessorKey: "name",
  },
  {
    header: "Phone",
    accessorKey: "phone",
    cell: (cell) => (
      <FormatPhone countryCode={countryCode} value={cell.getValue()} />
    ),
  },
  {
    header: "Mail",
    accessorKey: "email",
  },
  {
    accessorKey: "archived",
  },
];

export const SORTING_OPTIONS: SortingOptions<GetEmployeesItem> = {
  name: [
    {
      name: "A-Z",
      id: "name",
    },
    {
      name: "Z-A",
      id: "-name",
    },
  ],
};
