import { showSnackbarError } from "shared/lib";
import { axios } from "shared/lib/axios";

export const handleEmployeeMutationError = (error: Error) => {
  if (!(error instanceof axios.AxiosError)) return;

  const emailError = error?.response?.data?.error?.errors?.email?.[0];
  const phoneError = error?.response?.data?.error?.errors?.phone?.[0];
  const clientError = error?.response?.data?.error?.message;

  if (clientError) {
    showSnackbarError(clientError);
  }

  if (emailError) {
    showSnackbarError(emailError);
  }

  if (phoneError) {
    showSnackbarError(phoneError);
  }
};
