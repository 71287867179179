import { LayoutMain } from "widgets/layout-main";
import { TradeOutletList } from "widgets/trade-outlet-list";

export const PageTradeOutlets = () => {
  return (
    <LayoutMain>
      <TradeOutletList />
    </LayoutMain>
  );
};
