export const getClipboardString = (): Promise<string> => {
  if (navigator && navigator.clipboard) {
    return navigator.clipboard.readText();
  }

  const el = document.createElement("textarea");
  document.body.appendChild(el);
  el.select();
  document.execCommand("paste");

  const value = el.innerText;
  document.body.removeChild(el);

  return Promise.resolve(value);
};

export const setClipboardString = (content: string) => {
  if (navigator && navigator.clipboard) {
    navigator.clipboard.writeText(content);
  }

  const el = document.createElement("textarea");
  el.value = content;
  document.body.appendChild(el);
  el.select();

  document.execCommand("copy");
  document.body.removeChild(el);
};
