import { bnplMerchants } from "@mychili/frontend-libs-api-core";

export const uploadTradeOutletLogo = async (
  id: string,
  body: bnplMerchants.PostApiMerchantsServiceCrmMerchantsIdUploadLogoBody,
) => {
  const response =
    await bnplMerchants.postApiMerchantsServiceTradeOutletsIdUploadLogo(
      id,
      body,
    );

  if (!response.data.data) {
    const errorMessage = "Error uploading store logo";
    throw new Error(errorMessage);
  }

  return response.data.data;
};
