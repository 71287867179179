import { LayoutMain } from "widgets/layout-main";
import { OrderList } from "widgets/order-list";

export const PageOrders = () => {
  return (
    <LayoutMain>
      <OrderList />
    </LayoutMain>
  );
};
