import { Box, IconStore, Stack, SvgIconProps } from "shared/ui";

export type LogoProps = {
  size?: "s" | "m";
  src?: string;
  PlaceholderIcon?: (props: SvgIconProps) => JSX.Element;
};

const SIZES = {
  s: 40,
  m: 73,
};

export const Logo = ({
  src,
  PlaceholderIcon = IconStore,
  size = "m",
}: LogoProps) =>
  src ? (
    <Box
      component="img"
      src={src}
      width={SIZES[size]}
      height={SIZES[size]}
      borderRadius="50%"
    />
  ) : (
    <Stack
      width={SIZES[size]}
      height={SIZES[size]}
      borderRadius="50%"
      sx={{
        backgroundColor: "blueGray.95",
      }}
      alignItems="center"
      justifyContent="center"
    >
      <PlaceholderIcon
        fontSize={size === "m" ? "large" : "medium"}
        sx={{ color: "blueGray.80" }}
      />
    </Stack>
  );
