import { ColumnDef } from "@tanstack/react-table";
import { getOrdersPath } from "entities/orders";
import { GetOrdersItem } from "shared/api";
import { format } from "shared/lib";
import { TableCellLinkButton } from "shared/ui";

export const COLUMNS: ColumnDef<GetOrdersItem, any>[] = [
  {
    accessorKey: "orderNumber",
    header: "Order ID",
    cell: (cell) => {
      const orderNumber = cell.getValue();
      return (
        <TableCellLinkButton
          text={orderNumber}
          path={getOrdersPath({ search: orderNumber })}
        />
      );
    },
  },
  {
    accessorKey: "amount",
    header: "Order Amount",
    cell: (cell) => {
      const value = cell.getValue();
      return value ? format(value) : undefined;
    },
  },
  {
    accessorKey: "commissionAmount",
    header: "Comission",
    cell: (cell) => {
      const value = cell.getValue();
      return value ? format(value) : undefined;
    },
  },
  {
    accessorKey: "settlementAmount",
    header: "Payout",
    cell: (cell) => {
      const value = cell.getValue();
      return value ? format(value) : undefined;
    },
  },
  {
    accessorKey: "mdr",
    header: "MDR",
    cell: (cell) => {
      const value = cell.getValue();
      return value ? `${value}%` : "";
    },
  },
];
