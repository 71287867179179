import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "shared/routing";
import { Box, Container, Stack } from "shared/ui";
import { Navigation } from "widgets/navigation";

type LayoutMainProps = {
  children?: ReactNode;
};

export const LayoutMain = ({ children }: LayoutMainProps) => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: "common.white",
        }}
        py={1.75}
      >
        <Container>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            spacing={10}
          >
            <Link to={ROUTES.orders}>
              <Box
                component="img"
                display="block"
                width={92}
                src="https://static.ahapay.my/logos/full.svg"
              />
            </Link>

            <Navigation />
          </Stack>
        </Container>
      </Box>

      <Box mt={2.5}>
        <Container>{children}</Container>
      </Box>
    </>
  );
};
