import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getS3ImageSrc } from "shared/lib";
import {
  Box,
  Button,
  IconLocation,
  IconStore,
  Input,
  LogoUpload,
  Stack,
  Typography,
} from "shared/ui";

import { tradeOutletSchema } from "../lib";

export type TradeOutletFormData = {
  name: string;
  location: string;
  logoUrl?: string;
};

export type TradeOutletFormDataWithLogo = TradeOutletFormData & {
  logo: Blob | undefined;
};

type TradeOutletFormProps = {
  onClose: () => void;
  onSubmit: (data: TradeOutletFormDataWithLogo) => void;
  initialData?: TradeOutletFormData | undefined;
};

export const TradeOutletForm = ({
  onClose,
  onSubmit,
  initialData,
}: TradeOutletFormProps) => {
  const [logoBlob, setLogoBlob] = useState<Blob>();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<TradeOutletFormData>({
    resolver: yupResolver(tradeOutletSchema),
    reValidateMode: "onSubmit",
    defaultValues: initialData,
  });

  const handleFormSubmit = (data: TradeOutletFormData) => {
    onSubmit({
      ...data,
      logo: logoBlob,
    });
  };

  const handleLogoUploadComplete = (blob: Blob | undefined) => {
    setLogoBlob(blob);
  };

  useEffect(() => {
    if (initialData) {
      reset(initialData);
    }
  }, [initialData, reset]);

  return (
    <Box py={2.5}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Box
          px={2.5}
          pb={2.5}
          sx={{
            height: 392,
            overflowY: "auto",
          }}
        >
          <Typography mb={2} variant="smallTextMedium">
            Logo
          </Typography>
          <LogoUpload
            onComplete={handleLogoUploadComplete}
            initialLogoSrc={getS3ImageSrc(initialData?.logoUrl)}
          />

          <Stack spacing={1} mt={2.5}>
            <Typography mb={1} variant="smallTextMedium">
              Store's name
            </Typography>
            <Input
              {...register("name")}
              placeholder="Store's name"
              InputProps={{
                startAdornment: <IconStore />,
                sx: {
                  // @TODO Remove after adding small inputs to DS
                  ".MuiInputBase-input": {
                    cursor: "pointer",
                    py: 1.5,
                    fontSize: 14,
                    lineHeight: 21,
                  },
                },
              }}
              color="gray"
              error={Boolean(errors.name?.message)}
              helperText={errors.name?.message}
            />

            <Typography mb={1} variant="smallTextMedium">
              Address
            </Typography>
            <Input
              {...register("location")}
              placeholder="Address"
              InputProps={{
                startAdornment: <IconLocation />,
                sx: {
                  // @TODO Remove after adding small inputs to DS
                  ".MuiInputBase-input": {
                    cursor: "pointer",
                    py: 1.5,
                    fontSize: 14,
                    lineHeight: 21,
                  },
                },
              }}
              color="gray"
              error={Boolean(errors.location?.message)}
              helperText={errors.location?.message}
            />
          </Stack>
        </Box>

        <Stack
          direction="row"
          spacing={1}
          pt={2.5}
          px={2.5}
          // @TODO Add to ui-web after appering in DS
          boxShadow="-1px 0px 8px 0px rgba(65, 69, 77, 0.02), 4px 0px 8px 0px rgba(65, 69, 77, 0.04);"
          sx={{
            borderTop: "1px solid",
            borderColor: "neutral.95",
          }}
        >
          <Button onClick={onClose} fullWidth variant="secondary">
            Back
          </Button>
          <Button type="submit" fullWidth>
            Save changes
          </Button>
        </Stack>
      </form>
    </Box>
  );
};
