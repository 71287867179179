import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEY_EMPLOYEES } from "entities/employees";
import { createEmployee } from "shared/api";

export const useCreateEmployee = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createEmployee,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_EMPLOYEES] });
    },
  });
};
