import { useQuery } from "@tanstack/react-query";
import { getTradeOutletsNames } from "shared/api";

import { QUERY_KEY_TRADE_OUTLETS_OPTIONS } from "../../lib";

export const useTradeOutletsOptionsV2 = () =>
  useQuery({
    queryFn: getTradeOutletsNames,
    queryKey: QUERY_KEY_TRADE_OUTLETS_OPTIONS,
  });
