import { bnplMerchants } from "@mychili/frontend-libs-api-core";
import { enqueueSnackbar } from "@mychili/ui-web";

type DeleteEmployeeParams = {
  employeeId: string;
};

type DeleteEmployeeResponseData = {
  id: bnplMerchants.EmployeeMany["id"];
  created: bnplMerchants.EmployeeMany["created"];
  updated: bnplMerchants.EmployeeMany["updated"];
  archived: bnplMerchants.EmployeeMany["archived"];
  merchantId: bnplMerchants.EmployeeMany["merchant_id"];
  name: bnplMerchants.EmployeeMany["name"];
  role: bnplMerchants.EmployeeMany["role"];
  phone: bnplMerchants.EmployeeMany["phone"];
  email: bnplMerchants.EmployeeMany["email"];
};

export const deleteEmployee = async ({
  employeeId,
}: DeleteEmployeeParams): Promise<DeleteEmployeeResponseData> => {
  const response =
    await bnplMerchants.deleteApiMerchantsServiceEmployeesId(employeeId);

  if (!response.data.data) {
    const errorMessage = "Error deleting employee";
    enqueueSnackbar(errorMessage, { variant: "error" });
    throw new Error(errorMessage);
  }

  const {
    id,
    created,
    updated,
    archived,
    merchant_id,
    name,
    role,
    phone,
    email,
  } = response.data.data;

  return {
    id,
    created,
    updated,
    archived,
    merchantId: merchant_id,
    name,
    role,
    phone,
    email,
  };
};
