import { useQuery } from "@tanstack/react-query";
import { getEmployee } from "shared/api";

import { QUERY_KEY_USER } from "../../lib";

export const useUser = () =>
  useQuery({
    queryFn: getEmployee,
    queryKey: QUERY_KEY_USER,
  });
