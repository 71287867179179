import { bnplMerchants } from "@mychili/frontend-libs-api-core";

export type GetCodeRequestParams = {
  phoneNumber: bnplMerchants.SendCodeRequest["phone_number"];
};

export type GetCodeResponseData = {
  authId?: bnplMerchants.SendCodeResponse["auth_id"];
  authType?: bnplMerchants.SendCodeResponse["auth_type"];
  countdown?: bnplMerchants.SendCodeResponse["countdown"];
};

export const getCode = async (
  params: GetCodeRequestParams,
): Promise<GetCodeResponseData> => {
  const response = await bnplMerchants.postApiMerchantsServiceAuthSendCode({
    phone_number: params.phoneNumber,
  });

  return {
    authId: response.data.data?.auth_id,
    authType: response.data.data?.auth_type,
    countdown: response.data.data?.countdown,
  };
};
