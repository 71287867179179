import { GetEmployeeOutletsItem, GetTradeOutletNamesItem } from "shared/api";

export type TradeOutletsAccessState = {
  [id: string]: boolean;
};

export const getTradeOutletsAccessState = (
  options: GetTradeOutletNamesItem[],
  employeesOutlets: GetEmployeeOutletsItem[],
): TradeOutletsAccessState =>
  options.reduce((acc, option) => {
    return {
      ...acc,
      [option.id!]: employeesOutlets.some(
        (item) => item.tradeOutletId === option.id,
      ),
    };
  }, {});
