import { Box, CircularProgress } from "@mychili/ui-web";

type LoadingBaseProps = {
  size?: number;
  thickness?: number;
};

export const BaseLoader = ({ size = 40, thickness = 4 }: LoadingBaseProps) => (
  <Box sx={{ position: "relative" }}>
    <CircularProgress
      variant="determinate"
      size={size}
      thickness={thickness}
      sx={{
        color: "common.primaryContainer",
      }}
      value={100}
    />
    <CircularProgress
      variant="indeterminate"
      sx={{
        position: "absolute",
        left: 0,
      }}
      size={size}
      thickness={thickness}
    />
  </Box>
);

export const Loader = () => (
  <Box height="100%" display="flex" alignItems="center" justifyContent="center">
    <BaseLoader />
  </Box>
);
