import { bnplMerchants } from "@mychili/frontend-libs-api-core";

export type LogoutRequestParams = {
  accessToken: bnplMerchants.LogoutRequest["access_token"];
};

export type LogoutResponseData = {
  success: bnplMerchants.SuccessResponse["success"];
};

export const logout = async (
  params: LogoutRequestParams,
): Promise<LogoutResponseData> => {
  const response = await bnplMerchants.postApiMerchantsServiceAuthLogout({
    access_token: params.accessToken,
  });

  return {
    success: response.data.data?.success,
  };
};
