import React from "react";
import ReactDOM from "react-dom/client";
import { Axios } from "shared/lib/axios/axios";

import { App } from "./app";

Axios.init();

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
