import { useQuery } from "@tanstack/react-query";
import { getEmployeesNames } from "shared/api";

import { QUERY_KEY_EMPLOYEES_OPTIONS } from "../../lib";

export const useEmployeesOptionsV2 = () =>
  useQuery({
    queryFn: getEmployeesNames,
    queryKey: QUERY_KEY_EMPLOYEES_OPTIONS,
  });
