import { IconChevronDown, IconFilterOn } from "@mychili/ui-web";
import { useState } from "react";
import { Box, FilterItem, FilterType, Input, Popover } from "shared/ui";
// @TODO Update component in ui-web after redesign complete
import { DatePicker } from "shared/ui/date-picker-v2";

import { OptionsDropdown } from "./options-dropdown";

type FilterProps = FilterItem & {
  onChange: (
    field: string,
    values: string[] | Date[] | undefined,
    type: FilterItem["type"],
  ) => void;
  value: string[] | undefined;
};

export const Filter = ({
  onChange,
  field,
  value,
  type = FilterType.Multiple,
  options = [],
  entityLabel = "Selected",
}: FilterProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);

  const handleInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleChange = (
    filterValue: string | string[] | Date | Date[] | undefined,
  ) => {
    if (Array.isArray(filterValue) || filterValue === undefined)
      onChange(field, filterValue, type);
  };

  const handlePopoverClose = () => setAnchorEl(null);

  if (type === "range")
    return (
      <Box
        width={280}
        sx={{
          // @TODO Move to ui-web
          ".MuiFormLabel-root": {
            margin: 0,
          },
        }}
      >
        <DatePicker
          onChange={handleChange}
          range
          numberOfMonths={2}
          inputColor="gray"
          value={value}
          placeholder="Filter Dates"
        />
      </Box>
    );

  return (
    <>
      <Input
        color="gray"
        variant="filled"
        onClick={handleInputClick}
        size="small"
        InputProps={{
          endAdornment: value ? <IconFilterOn /> : <IconChevronDown />,
          readOnly: true,
          sx: {
            // @TODO Remove after adding small inputs to DS
            ".MuiInputBase-input": {
              cursor: "pointer",
              px: 0,
              py: 1.5,
              fontSize: 14,
              lineHeight: 21,
            },
          },
        }}
        placeholder={`Filter ${entityLabel}`}
        value={value ? `${entityLabel} (${value.length})` : ""}
        sx={{
          width: 174,
        }}
      />

      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        onClose={handlePopoverClose}
        open={Boolean(anchorEl)}
      >
        <OptionsDropdown
          type="check"
          title="Filters"
          options={options}
          value={value || []}
          onChange={handleChange}
          showSearch={true}
        />
      </Popover>
    </>
  );
};
