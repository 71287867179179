import "./styles";

import { SnackbarProvider } from "@mychili/ui-web";
import * as Sentry from "@sentry/react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import {
  QueryClientProvider,
  RouterProvider,
  SessionProvider,
  ThemeProvider,
} from "./providers";

Sentry.init({
  dsn: "https://34d3b75b844ddf5e19010ba72832c7ca@sentry.fingular.com/50",
  enabled: process.env.NODE_ENV === "production",
});

export const App = () => {
  return (
    <QueryClientProvider>
      <ThemeProvider>
        <SnackbarProvider
          autoHideDuration={3000}
          preventDuplicate
          maxSnack={3}
          dense={false}
          hideIconVariant
        >
          <SessionProvider>
            <RouterProvider />
          </SessionProvider>
        </SnackbarProvider>
      </ThemeProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
};
