import { queryClient } from "app/providers";

import {
  ACCESS_TOKEN_STORAGE_KEY,
  queryKey,
  REFRESH_TOKEN_STORAGE_KEY,
} from "../../lib";

export const unsetSession = () => {
  localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
  localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);

  return queryClient.removeQueries({ queryKey });
};
