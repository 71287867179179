import { Backdrop, BackdropProps, IconLoader } from "@mychili/ui-web";

export const LoadingOverlay = ({ open }: BackdropProps) => {
  return (
    <Backdrop
      sx={{
        backgroundColor: "rgba(255, 255, 255, 0.4)",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        opacity: 0.4,
      }}
      open={open}
    >
      <IconLoader
        sx={{
          animation: "spin 0.8s linear infinite",
          "@keyframes spin": {
            "0%": {
              transform: "rotate(360deg)",
            },
            "100%": {
              transform: "rotate(0deg)",
            },
          },
          color: "common.primary",
          fontSize: 48,
        }}
      />
    </Backdrop>
  );
};
