import { useEffect, useState } from "react";

export type TimerState = "started" | "stopped";

export const useTimer = (_seconds: number) => {
  const [state, setState] = useState<TimerState>("started");
  const [seconds, setSeconds] = useState(_seconds);

  const start = (countdown: number) => {
    setSeconds(countdown);
  };

  useEffect(() => {
    if (_seconds) {
      start(_seconds);
    }
  }, [_seconds]);

  useEffect(() => {
    if (seconds === 0) {
      return setState("stopped");
    }

    setState("started");
  }, [seconds]);

  useEffect(() => {
    let id: ReturnType<typeof setInterval> | undefined;

    if (state === "started") {
      id = setInterval(() => {
        setSeconds((prevSeconds) => {
          return prevSeconds - 1;
        });
      }, 1000);
    }

    if (state === "stopped") {
      clearInterval(id);
    }

    return () => {
      clearInterval(id);
    };
  }, [state]);

  return { seconds, start, state };
};
